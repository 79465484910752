



























































import Vue from "vue";
import { Component } from "nuxt-property-decorator";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import BaseInput from "~/components/core/BaseInput.vue";

@Component({
  name: "DeleteAccountComponent",
  components: { BaseInput },
})
export default class DeleteAccountModal extends Vue {
  email: string | null = null;
  errorMessage: string | null = null;
  emailInvalid = true;
  isLoading = false;

  get invalid() {
    return this.emailInvalid;
  }

  get faTimes() {
    return faTimes;
  }

  onClose() {
    if (process.browser && window) {
      window.location.hash = "";
    }
  }

  async onSubmit() {
    if (!this.email) {
      return;
    }
    this.isLoading = true;
    this.errorMessage = await this.$store.dispatch("deleteUser", this.email);

    if (this.errorMessage) {
      this.isLoading = false;
      return;
    }

    this.$notify({
      type: "success",
      message: this.$tc("components.modals.delete_account.notify_success"),
    });
    this.onSignOut();
    this.onClose();
    this.isLoading = false;
  }

  async onSignOut() {
    const errorMessage = await this.$store.dispatch("signOut");

    if (errorMessage) {
      this.$notify({ type: "error", message: errorMessage });
    }
  }
}
