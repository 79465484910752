import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _688fc733 = () => interopDefault(import('../pages/attributions.vue' /* webpackChunkName: "pages/attributions" */))
const _3c6af4fa = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _0c9804b4 = () => interopDefault(import('../pages/sitemap.vue' /* webpackChunkName: "pages/sitemap" */))
const _e86f7890 = () => interopDefault(import('../pages/terms.vue' /* webpackChunkName: "pages/terms" */))
const _3c59d6db = () => interopDefault(import('../pages/auth/register.vue' /* webpackChunkName: "pages/auth/register" */))
const _54ccaac0 = () => interopDefault(import('../pages/cookies/de.vue' /* webpackChunkName: "pages/cookies/de" */))
const _50655270 = () => interopDefault(import('../pages/cookies/en.vue' /* webpackChunkName: "pages/cookies/en" */))
const _3ce2a1f8 = () => interopDefault(import('../pages/impressum/de.vue' /* webpackChunkName: "pages/impressum/de" */))
const _3f164e20 = () => interopDefault(import('../pages/impressum/en.vue' /* webpackChunkName: "pages/impressum/en" */))
const _81724224 = () => interopDefault(import('../pages/mein-vanausbau/dashboard.vue' /* webpackChunkName: "pages/mein-vanausbau/dashboard" */))
const _6b1c545b = () => interopDefault(import('../pages/mein-vanausbau/plan-and-share.vue' /* webpackChunkName: "pages/mein-vanausbau/plan-and-share" */))
const _5b2eb3a0 = () => interopDefault(import('../pages/mein-vanausbau/pricing.vue' /* webpackChunkName: "pages/mein-vanausbau/pricing" */))
const _25030d8e = () => interopDefault(import('../pages/mein-vanausbau/settings.vue' /* webpackChunkName: "pages/mein-vanausbau/settings" */))
const _67470a40 = () => interopDefault(import('../pages/mein-vanausbau/van/index.vue' /* webpackChunkName: "pages/mein-vanausbau/van/index" */))
const _14fb20f2 = () => interopDefault(import('../pages/privacy/de.vue' /* webpackChunkName: "pages/privacy/de" */))
const _1093c8a2 = () => interopDefault(import('../pages/privacy/en.vue' /* webpackChunkName: "pages/privacy/en" */))
const _0d299f1a = () => interopDefault(import('../pages/mein-vanausbau/van/custom.vue' /* webpackChunkName: "pages/mein-vanausbau/van/custom" */))
const _7f10cb88 = () => interopDefault(import('../pages/mein-vanausbau/van/predefined.vue' /* webpackChunkName: "pages/mein-vanausbau/van/predefined" */))
const _fb53fc8c = () => interopDefault(import('../pages/auth/confirm/password/_email.vue' /* webpackChunkName: "pages/auth/confirm/password/_email" */))
const _61dc845c = () => interopDefault(import('../pages/auth/confirm/registration/_email.vue' /* webpackChunkName: "pages/auth/confirm/registration/_email" */))
const _2550c0a6 = () => interopDefault(import('../pages/inspiration/_query.vue' /* webpackChunkName: "pages/inspiration/_query" */))
const _56441fa6 = () => interopDefault(import('../pages/showcase/_project.vue' /* webpackChunkName: "pages/showcase/_project" */))
const _636c5235 = () => interopDefault(import('../pages/mein-vanausbau/_project/dashboard.vue' /* webpackChunkName: "pages/mein-vanausbau/_project/dashboard" */))
const _f3e33ed0 = () => interopDefault(import('../pages/mein-vanausbau/_project/plan.vue' /* webpackChunkName: "pages/mein-vanausbau/_project/plan" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/attributions",
    component: _688fc733,
    name: "attributions___de"
  }, {
    path: "/en",
    component: _3c6af4fa,
    name: "index___en"
  }, {
    path: "/sitemap",
    component: _0c9804b4,
    name: "sitemap___de"
  }, {
    path: "/terms",
    component: _e86f7890,
    name: "terms___de"
  }, {
    path: "/auth/register",
    component: _3c59d6db,
    name: "auth-register___de"
  }, {
    path: "/cookies/de",
    component: _54ccaac0,
    name: "cookies-de___de"
  }, {
    path: "/cookies/en",
    component: _50655270,
    name: "cookies-en___de"
  }, {
    path: "/en/attributions",
    component: _688fc733,
    name: "attributions___en"
  }, {
    path: "/en/sitemap",
    component: _0c9804b4,
    name: "sitemap___en"
  }, {
    path: "/en/terms",
    component: _e86f7890,
    name: "terms___en"
  }, {
    path: "/impressum/de",
    component: _3ce2a1f8,
    name: "impressum-de___de"
  }, {
    path: "/impressum/en",
    component: _3f164e20,
    name: "impressum-en___de"
  }, {
    path: "/mein-vanausbau/dashboard",
    component: _81724224,
    name: "mein-vanausbau-dashboard___de"
  }, {
    path: "/mein-vanausbau/plan-and-share",
    component: _6b1c545b,
    name: "mein-vanausbau-plan-and-share___de"
  }, {
    path: "/mein-vanausbau/pricing",
    component: _5b2eb3a0,
    name: "mein-vanausbau-pricing___de"
  }, {
    path: "/mein-vanausbau/settings",
    component: _25030d8e,
    name: "mein-vanausbau-settings___de"
  }, {
    path: "/mein-vanausbau/van",
    component: _67470a40,
    name: "mein-vanausbau-van___de"
  }, {
    path: "/privacy/de",
    component: _14fb20f2,
    name: "privacy-de___de"
  }, {
    path: "/privacy/en",
    component: _1093c8a2,
    name: "privacy-en___de"
  }, {
    path: "/en/auth/register",
    component: _3c59d6db,
    name: "auth-register___en"
  }, {
    path: "/en/cookies/de",
    component: _54ccaac0,
    name: "cookies-de___en"
  }, {
    path: "/en/cookies/en",
    component: _50655270,
    name: "cookies-en___en"
  }, {
    path: "/en/impressum/de",
    component: _3ce2a1f8,
    name: "impressum-de___en"
  }, {
    path: "/en/impressum/en",
    component: _3f164e20,
    name: "impressum-en___en"
  }, {
    path: "/en/mein-vanausbau/dashboard",
    component: _81724224,
    name: "mein-vanausbau-dashboard___en"
  }, {
    path: "/en/mein-vanausbau/plan-and-share",
    component: _6b1c545b,
    name: "mein-vanausbau-plan-and-share___en"
  }, {
    path: "/en/mein-vanausbau/pricing",
    component: _5b2eb3a0,
    name: "mein-vanausbau-pricing___en"
  }, {
    path: "/en/mein-vanausbau/settings",
    component: _25030d8e,
    name: "mein-vanausbau-settings___en"
  }, {
    path: "/en/mein-vanausbau/van",
    component: _67470a40,
    name: "mein-vanausbau-van___en"
  }, {
    path: "/en/privacy/de",
    component: _14fb20f2,
    name: "privacy-de___en"
  }, {
    path: "/en/privacy/en",
    component: _1093c8a2,
    name: "privacy-en___en"
  }, {
    path: "/mein-vanausbau/van/custom",
    component: _0d299f1a,
    name: "mein-vanausbau-van-custom___de"
  }, {
    path: "/mein-vanausbau/van/predefined",
    component: _7f10cb88,
    name: "mein-vanausbau-van-predefined___de"
  }, {
    path: "/en/mein-vanausbau/van/custom",
    component: _0d299f1a,
    name: "mein-vanausbau-van-custom___en"
  }, {
    path: "/en/mein-vanausbau/van/predefined",
    component: _7f10cb88,
    name: "mein-vanausbau-van-predefined___en"
  }, {
    path: "/en/auth/confirm/password/:email?",
    component: _fb53fc8c,
    name: "auth-confirm-password-email___en"
  }, {
    path: "/en/auth/confirm/registration/:email?",
    component: _61dc845c,
    name: "auth-confirm-registration-email___en"
  }, {
    path: "/auth/confirm/password/:email?",
    component: _fb53fc8c,
    name: "auth-confirm-password-email___de"
  }, {
    path: "/auth/confirm/registration/:email?",
    component: _61dc845c,
    name: "auth-confirm-registration-email___de"
  }, {
    path: "/en/inspiration/:query?",
    component: _2550c0a6,
    name: "inspiration-query___en"
  }, {
    path: "/en/showcase/:project?",
    component: _56441fa6,
    name: "showcase-project___en"
  }, {
    path: "/en/mein-vanausbau/:project?/dashboard",
    component: _636c5235,
    name: "mein-vanausbau-project-dashboard___en"
  }, {
    path: "/en/mein-vanausbau/:project?/plan",
    component: _f3e33ed0,
    name: "mein-vanausbau-project-plan___en"
  }, {
    path: "/inspiration/:query?",
    component: _2550c0a6,
    name: "inspiration-query___de"
  }, {
    path: "/showcase/:project?",
    component: _56441fa6,
    name: "showcase-project___de"
  }, {
    path: "/mein-vanausbau/:project?/dashboard",
    component: _636c5235,
    name: "mein-vanausbau-project-dashboard___de"
  }, {
    path: "/mein-vanausbau/:project?/plan",
    component: _f3e33ed0,
    name: "mein-vanausbau-project-plan___de"
  }, {
    path: "/",
    component: _3c6af4fa,
    name: "index___de"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
