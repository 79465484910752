//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import FeedbackButton from "~/components/layout/FeedbackButton.vue";
export default {
  name: "ErrorPageNotFound",
  components: { FeedbackButton },
};
