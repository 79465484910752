export enum Subscription {
  FREE = "FREE",
  PREMIUM = "PREMIUM",
  PREMIUMV2 = "PREMIUMV2",
  LIFELONG = "LIFELONG",
  UNLIMITED = "UNLIMITED",
}

export interface UserPayment {
  uid: number;
  stripe_payment_id: string;
  stripe_customer_id: string;
  created_at: string;
  expires_at: string;
  type: Subscription;
}

export interface UserInvoice {
  receiptUrl: string | null;
  amount: number;
  created: string;
}
