import { GetterTree, ActionTree, MutationTree } from "vuex";
import { v4 as uuidv4 } from "uuid";
import amplitude from "amplitude-js";
import { Project, SelectedProduct, Shape } from "../types/project";
import { hashHexToNumber, getLanguage } from "../shared";
import { TopCategory } from "../types/category";
import { ProjectResponse, ProjectsResponse } from "../types/response";
import { handleError } from "../shared/errorHandler";
import { Product, Texture } from "../types/product";
import {
  createProject,
  deleteProject,
  getProject,
  getPublicProject,
  getProjects,
  updateProjectProducts,
  updateProjectVan,
  createProjectFromTemplate,
} from "~/adapters/project";
import { paymentModuleActive } from "~/config";

export const getDefaultState = () =>
  ({
    activeProject: null,
    projects: [],
  } as {
    activeProject: Project | null;
    projects: Project[];
  });

export const state = () => getDefaultState();

export type RootState = ReturnType<typeof state>;

export const getters: GetterTree<RootState, RootState> = {
  projects: (state) => {
    return state.projects ? [...state.projects] : [];
  },

  activeProject: (state) => {
    return state.activeProject ? { ...state.activeProject } : null;
  },

  avgNumProducts: (state) => {
    const projects = state.projects ? [...state.projects] : [];

    if (projects.length <= 0) return 0;

    let count = 0;

    for (const project of projects) {
      if (project.products) {
        count += project.products.length;
      }
    }

    return count / projects.length;
  },

  maxNumProducts: (state) => {
    const projects = state.projects ? [...state.projects] : [];

    let max = 0;

    for (const project of projects) {
      if (project.products) {
        max = Math.max(max, project.products.length);
      }
    }

    return max;
  },
};

export const mutations: MutationTree<RootState> = {
  resetState(state) {
    Object.assign(state, getDefaultState());
  },

  setActiveProject(state, project: Project | null) {
    state.activeProject = project ? { ...project } : null;
  },

  addProjects(state, newProjects: Project[]) {
    if (!state.projects) state.projects = [];

    newProjects = newProjects.filter((n) => n);

    for (const newProject of newProjects) {
      const idx = state.projects.findIndex(
        (project) => project.id === newProject.id
      );

      if (idx > -1) {
        state.projects.splice(idx, 1, { ...newProject });
      } else {
        state.projects.push({ ...newProject });
      }
    }
  },

  deleteProject(state, projectId: number) {
    if (!state.projects) state.projects = [];

    const idx = state.projects.findIndex((project) => project.id === projectId);

    if (idx > -1) {
      state.projects.splice(idx, 1);
    }
  },

  changeProductName(state, payload: { idx: number; newName: string }) {
    if (
      !state.activeProject ||
      !state.activeProject.products ||
      payload.idx < 0
    ) {
      return;
    }
    state.activeProject.products[payload.idx].name = payload.newName;
  },

  changeGroundColor(state, newColor: number) {
    if (!state.activeProject) return;
    state.activeProject.ground_color = newColor;
  },

  changeWallsColor(state, newColor: number) {
    if (!state.activeProject) return;
    state.activeProject.walls_color = newColor;
  },

  changeProductAppearance(
    state,
    payload: { idx: number; newPid: number; newColor: number }
  ) {
    if (
      !state.activeProject ||
      !state.activeProject.products ||
      payload.idx < 0
    ) {
      return;
    }
    state.activeProject.products[payload.idx].color = payload.newColor;
    state.activeProject.products[payload.idx].pid = payload.newPid;
  },

  hideProduct(state, idx: number) {
    if (!state.activeProject || !state.activeProject.products || idx < 0) {
      return;
    }
    state.activeProject.products[idx].visible = false;
  },

  showProduct(state, idx: number) {
    if (!state.activeProject || !state.activeProject.products || idx < 0) {
      return;
    }
    state.activeProject.products[idx].visible = true;
  },

  deleteProduct(state, idx: number) {
    if (!state.activeProject || !state.activeProject.products || idx < 0) {
      return;
    }
    state.activeProject.products.splice(idx, 1);
  },

  addProduct(state, product: any) {
    if (!state.activeProject || !state.activeProject.products) return;
    state.activeProject.products.push(product);
  },

  moveProduct(
    state,
    payload: { idx: number; deltaX: number; deltaY: number; deltaZ: number }
  ) {
    if (
      !state.activeProject ||
      !state.activeProject.products ||
      payload.idx < 0
    ) {
      return;
    }
    state.activeProject.products[payload.idx].x += payload.deltaX;
    state.activeProject.products[payload.idx].y += payload.deltaY;
    state.activeProject.products[payload.idx].z += payload.deltaZ;
  },

  rotateProduct(
    state,
    payload: { idx: number; deltaRx: number; deltaRy: number; deltaRz: number }
  ) {
    if (
      !state.activeProject ||
      !state.activeProject.products ||
      payload.idx < 0
    ) {
      return;
    }
    state.activeProject.products[payload.idx].rX += payload.deltaRx;
    state.activeProject.products[payload.idx].rY += payload.deltaRy;
    state.activeProject.products[payload.idx].rZ += payload.deltaRz;
  },

  scaleProduct(
    state,
    payload: { idx: number; deltaSx: number; deltaSy: number; deltaSz: number }
  ) {
    if (
      !state.activeProject ||
      !state.activeProject.products ||
      payload.idx < 0
    ) {
      return;
    }
    state.activeProject.products[payload.idx].sX += payload.deltaSx;
    state.activeProject.products[payload.idx].sY += payload.deltaSy;
    state.activeProject.products[payload.idx].sZ += payload.deltaSz;
  },
};

export const actions: ActionTree<RootState, RootState> = {
  changeProductName(
    { getters, commit },
    payload: { uuid: string; newName: string }
  ) {
    try {
      const project: Project = getters.activeProject;

      if (!project || !project.products) {
        return null;
      }

      const idx = project.products.findIndex(
        (iProduct) => iProduct.uuid === payload.uuid
      );

      if (idx >= 0) {
        commit("changeProductName", { idx, newName: payload.newName });
      }

      return getters.activeProject;
    } catch (err) {
      return handleError(
        err,
        getLanguage(this.$i18n.locale),
        undefined,
        {
          file: "store/projects",
          method: "changeProductName",
        },
        true
      );
    }
  },

  changeGroundColor({ getters, commit }, newColor: number) {
    try {
      const project: Project = getters.activeProject;

      if (!project) {
        return null;
      }

      const oldColor = project.ground_color;
      commit("changeGroundColor", newColor);

      return { project: getters.activeProject, oldColor };
    } catch (err) {
      return handleError(
        err,
        getLanguage(this.$i18n.locale),
        undefined,
        {
          file: "store/projects",
          method: "changeGroundColor",
        },
        true
      );
    }
  },

  changeWallsColor({ getters, commit }, newColor: number) {
    try {
      const project: Project = getters.activeProject;

      if (!project) {
        return null;
      }

      const oldColor = project.walls_color;
      commit("changeWallsColor", newColor);

      return { project: getters.activeProject, oldColor };
    } catch (err) {
      return handleError(
        err,
        getLanguage(this.$i18n.locale),
        undefined,
        {
          file: "store/projects",
          method: "changeWallsColor",
        },
        true
      );
    }
  },

  changeProductAppearance(
    { getters, commit },
    payload: { uuid: string; newPid: number | null; newColor: number | null }
  ) {
    try {
      const project: Project = getters.activeProject;

      if (!project || !project.products) {
        return null;
      }

      const idx = project.products.findIndex(
        (iProduct) => iProduct.uuid === payload.uuid
      );

      if (idx < 0) {
        return null;
      }

      const oldColor = project.products[idx].color;
      const oldProduct = { ...project.products[idx] };

      commit("changeProductAppearance", {
        idx,
        newPid: payload.newPid,
        newColor: payload.newColor,
      });

      const newColor = project.products[idx].color;

      return {
        project: getters.activeProject,
        oldProduct,
        newProduct: { ...getters.activeProject.products[idx] },
        uuid: getters.activeProject.products[idx].uuid,
        pid: getters.activeProject.products[idx].pid,
        oldColor,
        newColor,
      };
    } catch (err) {
      return handleError(
        err,
        getLanguage(this.$i18n.locale),
        undefined,
        {
          file: "store/projects",
          method: "changeProductAppearance",
        },
        true
      );
    }
  },

  hideProduct(
    { getters, commit },
    payload: {
      uuid: string;
      pid: number | null;
      ground?: boolean;
      walls?: boolean;
    }
  ) {
    try {
      const project: Project = getters.activeProject;

      if (!project || !project.products) {
        return null;
      }

      const idx = project.products.findIndex(
        (iProduct) => iProduct.uuid === payload.uuid
      );

      if (idx < 0) {
        return null;
      }

      const hideProduct = { ...project.products[idx] };

      commit("hideProduct", idx);

      let oldColor: number | null = null;

      if (payload.walls) {
        oldColor = project.walls_color;
      } else if (payload.ground) {
        oldColor = project.ground_color;
      }

      return { oldColor, hideProduct, project: getters.activeProject };
    } catch (err) {
      return handleError(
        err,
        getLanguage(this.$i18n.locale),
        undefined,
        {
          file: "store/projects",
          method: "hideProduct",
        },
        true
      );
    }
  },

  showProduct(
    { getters, commit },
    payload: {
      uuid: string;
      pid: number | null;
      ground?: boolean;
      walls?: boolean;
    }
  ) {
    try {
      const project: Project = getters.activeProject;

      if (!project || !project.products) {
        return null;
      }

      const idx = project.products.findIndex(
        (iProduct) => iProduct.uuid === payload.uuid
      );

      if (idx < 0) {
        return null;
      }

      const showProduct = { ...project.products[idx] };

      commit("showProduct", idx);

      let oldColor: number | null = null;

      if (payload.walls) {
        oldColor = project.walls_color;
      } else if (payload.ground) {
        oldColor = project.ground_color;
      }

      return { oldColor, showProduct, project: getters.activeProject };
    } catch (err) {
      return handleError(
        err,
        getLanguage(this.$i18n.locale),
        undefined,
        {
          file: "store/projects",
          method: "showProduct",
        },
        true
      );
    }
  },

  deleteProduct(
    { getters, commit },
    payload: {
      uuid: string;
      pid: number | null;
      ground?: boolean;
      walls?: boolean;
    }
  ) {
    try {
      const project: Project = getters.activeProject;

      if (!project || !project.products) {
        return null;
      }

      const idx = project.products.findIndex(
        (iProduct) => iProduct.uuid === payload.uuid
      );

      if (idx < 0) {
        return null;
      }

      const delProduct = { ...project.products[idx] };

      commit("deleteProduct", idx);

      let oldColor: number | null = null;

      if (payload.walls) {
        oldColor = project.walls_color;
      } else if (payload.ground) {
        oldColor = project.ground_color;
      }

      return { oldColor, delProduct, project: getters.activeProject };
    } catch (err) {
      return handleError(
        err,
        getLanguage(this.$i18n.locale),
        undefined,
        {
          file: "store/projects",
          method: "deleteProduct",
        },
        true
      );
    }
  },

  addProduct(
    { getters, commit, rootGetters },
    product: {
      pid: number;
      uuid?: string | undefined | null;
      width: number;
      height: number;
      length: number;
      color?: undefined | null | string;
      shape?: undefined | null | Shape;
      name: string;
      visible: boolean;
      x?: number | undefined; // For duplication
      z?: number | undefined; // For duplication
      sX?: number | undefined; // For duplication
      sY?: number | undefined; // For duplication
      sZ?: number | undefined; // For duplication
    }
  ) {
    try {
      const project: Project = getters.activeProject;
      const isPremiumV2: boolean = rootGetters.isPremiumV2;
      const isFree: boolean = rootGetters.isFree;

      if (!project) {
        return null;
      }

      if (!project.products) project.products = [];

      if (paymentModuleActive && isFree && project.products.length >= 10) {
        return this.$showPaymentBlocker(
          this.$i18n.tc("popups.payment_blocker.too_many_products.heading"),
          this.$i18n.tc("popups.payment_blocker.too_many_products.btn")
        );
      }

      if (paymentModuleActive && isPremiumV2 && project.products.length >= 30) {
        return this.$showPaymentBlocker(
          this.$i18n.tc(
            "popups.payment_blocker.too_many_products_premium.heading"
          ),
          this.$i18n.tc("popups.payment_blocker.too_many_products_premium.btn")
        );
      }

      const uuid = typeof product.uuid === "string" ? product.uuid : uuidv4();

      const addProduct = {
        x: product.x != null ? product.x : 0,
        y: product.height / 2, // Otherwise object is half in ground
        z: product.z != null ? product.z : 0,
        rX: 0,
        rY: 0,
        rZ: 0,
        sX: product.sX != null ? product.sX : 1,
        sY: product.sY != null ? product.sY : 1,
        sZ: product.sZ != null ? product.sZ : 1,
        uuid,
        color: getColor(product.color),
        pid: product.pid,
        width: product.width,
        height: product.height,
        length: product.length,
        shape: product.shape ?? null,
        name: getProductName(project, product),
        visible: product.visible,
      };

      commit("addProduct", addProduct);

      if (process.client) {
        amplitude.getInstance().logEvent("add_product", {
          product: product.pid,
          shape: product.shape,
        });
      }

      const oldColor = null;

      return { oldColor, addProduct, project: getters.activeProject };
    } catch (err) {
      return handleError(
        err,
        getLanguage(this.$i18n.locale),
        undefined,
        {
          file: "store/projects",
          method: "addProduct",
        },
        true
      );
    }
  },

  addTexture(
    { getters, commit },
    product: {
      pid: number;
      uuid?: string | undefined | null;
      ground: boolean;
      walls: boolean;
      color?: undefined | null | string;
      shape?: undefined | null | Shape;
      name: string;
      visible: boolean;
    }
  ) {
    try {
      const project: Project = getters.activeProject;

      if (!project) {
        return null;
      }

      if (!project.products) project.products = [];

      const uuid = typeof product.uuid === "string" ? product.uuid : uuidv4();

      const addProduct = {
        x: 0,
        y: 0,
        z: 0,
        rX: 0,
        rY: 0,
        rZ: 0,
        sX: 1,
        sY: 1,
        sZ: 1,
        uuid,
        color: getColor(product.color),
        pid: product.pid,
        width: 1,
        height: 1,
        length: 1,
        shape: null,
        name: product.name,
        visible: product.visible,
      };

      commit("addProduct", addProduct);

      let oldColor: number | null = null;

      if (product.walls) {
        oldColor = project.walls_color;
      } else if (product.ground) {
        oldColor = project.ground_color;
      }

      return { oldColor, addProduct, project: getters.activeProject };
    } catch (err) {
      return handleError(
        err,
        getLanguage(this.$i18n.locale),
        undefined,
        {
          file: "store/projects",
          method: "addTexture",
        },
        true
      );
    }
  },

  moveProduct(
    { getters, commit },
    product: {
      pid: number | null;
      uuid: string;
      newX: number;
      newY: number;
      newZ: number;
      moveInWorld?: boolean;
    }
  ) {
    try {
      const project: Project = getters.activeProject;

      if (!project || !project.products) {
        return null;
      }

      const idx = project.products.findIndex(
        (iProduct) => iProduct.uuid === product.uuid
      );

      if (idx < 0) {
        return null;
      }

      const oldProduct = { ...project.products[idx] };
      const deltaX = product.newX - project.products[idx].x;
      const deltaY = product.newY - project.products[idx].y;
      const deltaZ = product.newZ - project.products[idx].z;

      commit("moveProduct", { idx, deltaX, deltaY, deltaZ });

      return {
        project: getters.activeProject,
        deltaX,
        deltaY,
        deltaZ,
        oldProduct,
        newProduct: { ...project.products[idx] },
      };
    } catch (err) {
      return handleError(
        err,
        getLanguage(this.$i18n.locale),
        undefined,
        {
          file: "store/projects",
          method: "moveProduct",
        },
        true
      );
    }
  },

  rotateProduct(
    { getters, commit },
    product: {
      pid: number | null;
      uuid: string;
      newRx: number;
      newRy: number;
      newRz: number;
      moveInWorld?: boolean;
    }
  ) {
    try {
      const project: Project = getters.activeProject;

      if (!project || !project.products) {
        return null;
      }

      const idx = project.products.findIndex(
        (iProduct) => iProduct.uuid === product.uuid
      );

      if (idx < 0) {
        return null;
      }

      const oldProduct = { ...project.products[idx] };
      const deltaRx = product.newRx - project.products[idx].rX;
      const deltaRy = product.newRy - project.products[idx].rY;
      const deltaRz = product.newRz - project.products[idx].rZ;

      commit("rotateProduct", { idx, deltaRx, deltaRy, deltaRz });

      return {
        project: getters.activeProject,
        deltaRx,
        deltaRy,
        deltaRz,
        oldProduct,
        newProduct: { ...project.products[idx] },
      };
    } catch (err) {
      return handleError(
        err,
        getLanguage(this.$i18n.locale),
        undefined,
        {
          file: "store/projects",
          method: "rotateProduct",
        },
        true
      );
    }
  },

  scaleProduct(
    { getters, commit },
    product: {
      pid: number | null;
      uuid: string;
      newSx: number;
      newSy: number;
      newSz: number;
      moveInWorld?: boolean;
    }
  ) {
    try {
      const project: Project = getters.activeProject;

      if (!project || !project.products) {
        return null;
      }

      const idx = project.products.findIndex(
        (iProduct) => iProduct.uuid === product.uuid
      );

      if (idx < 0) {
        return null;
      }

      const oldProduct = { ...project.products[idx] };
      const deltaSx = product.newSx - project.products[idx].sX;
      const deltaSy = product.newSy - project.products[idx].sY;
      const deltaSz = product.newSz - project.products[idx].sZ;

      commit("scaleProduct", { idx, deltaSx, deltaSy, deltaSz });

      return {
        project: getters.activeProject,
        deltaSx,
        deltaSy,
        deltaSz,
        oldProduct,
        newProduct: { ...project.products[idx] },
      };
    } catch (err) {
      return handleError(
        err,
        getLanguage(this.$i18n.locale),
        undefined,
        {
          file: "store/projects",
          method: "scaleProduct",
        },
        true
      );
    }
  },

  fetchProject({ commit }, projectId: number) {
    commit("setActiveProject", null);

    return new Promise((resolve) => {
      const queueRes = this.$queue.push(getProject(this.$axios), [projectId]);

      if (!queueRes) return resolve(null);

      queueRes.then(
        (response: ProjectResponse) => {
          commit("setActiveProject", response.project);
          resolve(null);
        },
        (err) => {
          const errorMessage = handleError(
            err,
            getLanguage(this.$i18n.locale),
            undefined,
            {
              file: "store/projects",
              method: "getProject",
            },
            true
          );
          resolve(errorMessage);
        }
      );
    });
  },

  getPublicProject({ commit, rootGetters }, projectId: number) {
    commit("setActiveProject", null);

    return new Promise((resolve) => {
      const queueRes = this.$queue.push(getPublicProject(this.$axios), [
        projectId,
      ]);

      if (!queueRes) return resolve(null);

      queueRes.then(
        (response: ProjectResponse) => {
          commit("setActiveProject", response.project);

          resolve(null);
        },
        (err) => {
          const feedItem = rootGetters["feed/getFeedById"](projectId);

          if (feedItem) {
            resolve(feedItem);
            return;
          }

          const errorMessage = handleError(
            err,
            getLanguage(this.$i18n.locale),
            undefined,
            {
              file: "store/projects",
              method: "getPublicProject",
            },
            true
          );
          resolve(errorMessage);
        }
      );
    });
  },

  fetchProjects({ commit }) {
    return new Promise((resolve) => {
      const queueRes = this.$queue.push(getProjects(this.$axios), []);

      if (!queueRes) return resolve(null);

      queueRes.then(
        (response: ProjectsResponse) => {
          commit("addProjects", response.projects);
          resolve(null);
        },
        (err) => {
          const errorMessage = handleError(
            err,
            getLanguage(this.$i18n.locale),
            undefined,
            {
              file: "store/projects",
              method: "getProjects",
            },
            true
          );
          resolve(errorMessage);
        }
      );
    });
  },

  deleteProject({ getters, commit }, projectId: number) {
    return new Promise((resolve) => {
      const queueRes = this.$queue.push(deleteProject(this.$axios), [
        projectId,
      ]);

      if (!queueRes) return resolve(null);

      queueRes.then(
        () => {
          commit("deleteProject", projectId);
          commit("setActiveProject", null);

          if (process.client) {
            amplitude.getInstance().logEvent("delete_project");

            const identify = new amplitude.Identify()
              .set("num_projects", getters.projects.length)
              .set("avg_num_products", getters.avgNumProducts)
              .set("max_num_products", getters.maxNumProducts);
            amplitude.getInstance().identify(identify);
          }

          resolve(null);
        },
        (err) => {
          const errorMessage = handleError(
            err,
            getLanguage(this.$i18n.locale),
            undefined,
            {
              file: "store/projects",
              method: "deleteProject",
            },
            true
          );
          resolve(errorMessage);
        }
      );
    });
  },

  createProject(
    { commit, getters, rootGetters },
    data: {
      width: number;
      height: number;
      length: number;
      weight: number;
      image: string;
      title: string;
      model?: string | undefined | null;
    }
  ) {
    const projects: Project[] = getters.projects;

    const isPremiumV2: boolean = rootGetters.isPremiumV2;
    const isFree: boolean = rootGetters.isFree;

    if (paymentModuleActive && isFree && projects.length >= 1) {
      return this.$showPaymentBlocker(
        this.$i18n.tc("popups.payment_blocker.too_many_projects.heading"),
        this.$i18n.tc("popups.payment_blocker.too_many_projects.heading")
      );
    }

    if (paymentModuleActive && isPremiumV2 && projects.length >= 3) {
      return this.$showPaymentBlocker(
        this.$i18n.tc(
          "popups.payment_blocker.too_many_projects_premium.heading"
        ),
        this.$i18n.tc(
          "popups.payment_blocker.too_many_projects_premium.heading"
        )
      );
    }

    return new Promise((resolve) => {
      const queueRes = this.$queue.push(createProject(this.$axios), [data]);

      if (!queueRes) return resolve(null);

      queueRes.then(
        (response: ProjectResponse) => {
          commit("addProjects", [response.project]);
          commit("setActiveProject", response.project);

          if (process.client) {
            amplitude.getInstance().logEvent("create_project");

            const identify = new amplitude.Identify()
              .set("num_projects", getters.projects.length)
              .set("avg_num_products", getters.avgNumProducts)
              .set("max_num_products", getters.maxNumProducts);
            amplitude.getInstance().identify(identify);

            if (data.model) {
              amplitude
                .getInstance()
                .logEvent("select_van", { supplier: data.model });
            }
          }

          resolve(null);
        },
        (err) => {
          const errorMessage = handleError(
            err,
            getLanguage(this.$i18n.locale),
            undefined,
            {
              file: "store/projects",
              method: "createProject",
            },
            true
          );
          resolve(errorMessage);
        }
      );
    });
  },

  createProjectFromTemplate(
    { commit, getters, rootGetters },
    data: {
      width: number;
      height: number;
      length: number;
      weight: number;
      image: string;
      title: string;
      model?: string | undefined | null;
      products: SelectedProduct[];
      ground_color: number;
      walls_color: number;
    }
  ) {
    const projects: Project[] = getters.projects;

    const isPremiumV2: boolean = rootGetters.isPremiumV2;
    const isFree: boolean = rootGetters.isFree;

    if (paymentModuleActive && isFree && projects.length >= 1) {
      return this.$showPaymentBlocker(
        this.$i18n.tc("popups.payment_blocker.too_many_projects.heading"),
        this.$i18n.tc("popups.payment_blocker.too_many_projects.heading")
      );
    }

    if (paymentModuleActive && isPremiumV2 && projects.length >= 3) {
      return this.$showPaymentBlocker(
        this.$i18n.tc(
          "popups.payment_blocker.too_many_projects_premium.heading"
        ),
        this.$i18n.tc(
          "popups.payment_blocker.too_many_projects_premium.heading"
        )
      );
    }

    return new Promise((resolve) => {
      const queueRes = this.$queue.push(
        createProjectFromTemplate(this.$axios),
        [data]
      );

      if (!queueRes) return resolve(null);

      queueRes.then(
        (response: ProjectResponse) => {
          commit("addProjects", [response.project]);
          commit("setActiveProject", response.project);

          if (process.client) {
            amplitude.getInstance().logEvent("create_project");

            const identify = new amplitude.Identify()
              .set("num_projects", getters.projects.length)
              .set("avg_num_products", getters.avgNumProducts)
              .set("max_num_products", getters.maxNumProducts);
            amplitude.getInstance().identify(identify);

            if (data.model) {
              amplitude
                .getInstance()
                .logEvent("select_van", { supplier: data.model });
            }
          }

          resolve(null);
        },
        (err) => {
          const errorMessage = handleError(
            err,
            getLanguage(this.$i18n.locale),
            undefined,
            {
              file: "store/projects",
              method: "createProjectFromTemplate",
            },
            true
          );
          resolve(errorMessage);
        }
      );
    });
  },

  duplicateProject({ commit, getters, rootGetters }, project: Project) {
    const projects: Project[] = getters.projects;

    const isPremiumV2: boolean = rootGetters.isPremiumV2;
    const isFree: boolean = rootGetters.isFree;

    if (paymentModuleActive && isFree && projects.length >= 1) {
      return this.$showPaymentBlocker(
        this.$i18n.tc("popups.payment_blocker.too_many_projects.heading"),
        this.$i18n.tc("popups.payment_blocker.too_many_projects.heading")
      );
    }

    if (paymentModuleActive && isPremiumV2 && projects.length >= 3) {
      return this.$showPaymentBlocker(
        this.$i18n.tc(
          "popups.payment_blocker.too_many_projects_premium.heading"
        ),
        this.$i18n.tc(
          "popups.payment_blocker.too_many_projects_premium.heading"
        )
      );
    }

    return new Promise((resolve) => {
      const onError = (err: any) => {
        const errorMessage = handleError(
          err,
          getLanguage(this.$i18n.locale),
          undefined,
          {
            file: "store/projects",
            method: "duplicateProject",
          },
          true
        );
        resolve(errorMessage);
      };

      const onSuccess = (newProjectResponse: ProjectResponse) => {
        const queueRes = this.$queue.push(updateProjectProducts(this.$axios), [
          newProjectResponse.project.id,
          {
            products: project.products,
            groundColor: project.ground_color,
            wallColor: project.walls_color,
          },
        ]);

        if (!queueRes) return resolve(null);

        queueRes.then((response: ProjectResponse) => {
          commit("addProjects", [response.project]);
          commit("setActiveProject", response.project);

          if (process.client) {
            amplitude.getInstance().logEvent("duplicate_project");

            const identify = new amplitude.Identify()
              .set("num_projects", getters.projects.length)
              .set("avg_num_products", getters.avgNumProducts)
              .set("max_num_products", getters.maxNumProducts);
            amplitude.getInstance().identify(identify);

            if (project.model) {
              amplitude
                .getInstance()
                .logEvent("select_van", { supplier: project.model });
            }
          }

          resolve(null);
        }, onError);
      };

      const queueRes = this.$queue.push(createProject(this.$axios), [
        {
          ...project,
          title: project.title + " copy",
        },
      ]);

      if (!queueRes) return resolve(null);

      queueRes.then(onSuccess, onError);
    });
  },

  updateProjectProducts({ getters, commit, rootGetters }) {
    const project: Project = getters.activeProject;
    const isPremiumV2: boolean = rootGetters.isPremiumV2;
    const isFree: boolean = rootGetters.isFree;

    if (!project) {
      return null;
    }

    if (!project.products) project.products = [];

    if (paymentModuleActive && isFree && project.products.length > 10) {
      return this.$showPaymentBlocker(
        this.$i18n.tc("popups.payment_blocker.too_many_products.heading"),
        this.$i18n.tc("popups.payment_blocker.too_many_products.heading")
      );
    }

    if (paymentModuleActive && isPremiumV2 && project.products.length > 30) {
      return this.$showPaymentBlocker(
        this.$i18n.tc(
          "popups.payment_blocker.too_many_products_premium.heading"
        ),
        this.$i18n.tc(
          "popups.payment_blocker.too_many_products_premium.heading"
        )
      );
    }

    return new Promise((resolve) => {
      const queueRes = this.$queue.push(updateProjectProducts(this.$axios), [
        getters.activeProject.id,
        {
          products: getters.activeProject.products,
          wallColor: getters.activeProject.walls_color,
          groundColor: getters.activeProject.ground_color,
        },
      ]);

      if (!queueRes) return resolve(null);

      queueRes.then(
        (response: ProjectResponse) => {
          commit("addProjects", [response.project]);
          commit("setActiveProject", response.project);

          if (process.client) {
            amplitude.getInstance().logEvent("update_project_products");

            const identify = new amplitude.Identify()
              .set("avg_num_products", getters.avgNumProducts)
              .set("max_num_products", getters.maxNumProducts);
            amplitude.getInstance().identify(identify);
          }

          resolve(null);
        },
        (err) => {
          const errorMessage = handleError(
            err,
            getLanguage(this.$i18n.locale),
            undefined,
            {
              file: "store/projects",
              method: "updateProjectProducts",
            },
            true
          );
          resolve(errorMessage);
        }
      );
    });
  },

  updateProjectVan(
    { commit },
    payload: {
      projectId: number;
      data: {
        width: number;
        height: number;
        length: number;
        weight: number;
        image: string;
        title: string;
        model?: string | undefined | null;
      };
    }
  ) {
    return new Promise((resolve) => {
      const queueRes = this.$queue.push(updateProjectVan(this.$axios), [
        payload.projectId,
        payload.data,
      ]);

      if (!queueRes) return resolve(null);

      queueRes.then(
        (response: ProjectResponse) => {
          commit("addProjects", [response.project]);
          commit("setActiveProject", response.project);

          if (process.client && payload.data.model) {
            amplitude
              .getInstance()
              .logEvent("select_van", { supplier: payload.data.model });
          }

          resolve(null);
        },
        (err) => {
          const errorMessage = handleError(
            err,
            getLanguage(this.$i18n.locale),
            undefined,
            {
              file: "store/projects",
              method: "updateProjectVan",
            },
            true
          );
          resolve(errorMessage);
        }
      );
    });
  },

  getPidOfTopcategoryInProject(
    { getters, rootGetters },
    topcategory: TopCategory
  ) {
    const pids = [] as Array<{ pid: number | null; uuid: string }>;
    const project: Project | null = getters.activeProject;

    if (!project || !project.products) {
      return pids;
    }

    for (const product of project.products) {
      let productDetails: Texture | Product | null;

      productDetails = rootGetters["products/productDetails"](product.pid);

      if (!productDetails) {
        productDetails = rootGetters["products/textureDetails"](product.pid);
      }

      if (!productDetails) {
        continue;
      }

      if (productDetails.topcategory === topcategory && product.pid != null) {
        pids.push({ pid: product.pid, uuid: product.uuid });
      } else if (product.pid == null) {
        pids.push({ pid: product.pid, uuid: product.uuid });
      }
    }

    return pids;
  },
};

function getProductName(
  project: Project,
  product: {
    pid: number;
    width: number;
    height: number;
    length: number;
    color?: undefined | null | string;
    shape?: undefined | null | Shape;
    name: string;
    visible: boolean;
    x?: number | undefined; // For duplication
    z?: number | undefined; // For duplication
  }
) {
  if (product.name != null && product.name.length > 0) {
    return product.name;
  }

  if (product.shape != null && product.shape.length > 0) {
    return product.shape;
  }

  const productsWithPid = project.products?.filter(
    (iProduct) => iProduct.pid === product.pid
  );

  let no = 1;

  if (productsWithPid && product.pid != null) {
    no = productsWithPid.length;
  } else if (productsWithPid && product.pid == null) {
    no = 1 + productsWithPid.length;
  }

  return `${no}`;
}

function getColor(productColor: undefined | null | string | number) {
  if (typeof productColor === "string") return hashHexToNumber(productColor);
  else if (typeof productColor === "number") return productColor;
  else return null;
}
