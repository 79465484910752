import Vue from "vue";
import { Lang } from "../types/locale";
import {
  getCorsErrorMessage,
  getDefaulErrorMessage,
  getInternetOfflineErrorMessage,
} from "../locales/translations";
import { log } from "./logger";
import { $sentryReady } from "./accessors/sentry";

export function handleError(
  error: any,
  lang: Lang,
  app?: Vue | undefined,
  context?: any | undefined,
  forceSentryPush: boolean = false
): string {
  if (process.env.NODE_ENV !== "production") {
    log(error);
  }

  const _error = error as any;

  const errorMessage = getErrorMessage(_error, lang);

  try {
    const code =
      _error?.response?.data?.error?.code ||
      _error?.response?.data?.code ||
      "--";

    let stringifiedError: string | undefined;

    try {
      stringifiedError = JSON.stringify(
        _error,
        Object.getOwnPropertyNames(_error)
      );
    } catch (err) {}

    let _context = { ...context, errorMessage, backendErrorCode: code };

    if (stringifiedError) {
      _context = { ..._context, errorObject: stringifiedError };
    }

    if (getHttpStatusCode(error) < 500 && !forceSentryPush) {
      return errorMessage;
    }

    if (app) {
      app.$sentry.setContext("Custom Context", _context);
      app.$sentry.captureException(error);
    } else if ($sentryReady) {
      $sentryReady().then(($sentry) => {
        $sentry.setContext("Custom Context", _context);
        $sentry.captureException(error);
      });
    }

    return errorMessage;
  } catch (err: any) {
    if (process.env.NODE_ENV !== "production") {
      log("While handling this error another error occured.");
      log(error);
      log(err);
    }
  }

  return errorMessage;
}

function getHttpStatusCode(error: any): number {
  if (error && error.response && error.response.status) {
    return error.response.status;
  } else {
    return 500;
  }
}

function getErrorMessage(error: any, lang: Lang): string {
  const defaultErrorMsg = getDefaulErrorMessage(lang);

  try {
    if (!error || (error && !error.response)) {
      // Network Error
      let isOnline = true;

      if (process.client && window && window.navigator) {
        isOnline = window.navigator.onLine;
      }
      return isOnline
        ? getCorsErrorMessage(lang)
        : getInternetOfflineErrorMessage(lang);
    } else {
      const responseErrorMsg =
        error?.response?.data?.error?.message || error?.response?.data?.message;

      const errorMsgProvided = typeof responseErrorMsg === "string";

      return errorMsgProvided ? responseErrorMsg : defaultErrorMsg;
    }
  } catch (err: any) {
    if (process.env.NODE_ENV !== "production") {
      log("While handling this error another error occured.");
      log(error);
      log(err);
    }
  }

  return defaultErrorMsg;
}
