import { Context, Plugin } from "@nuxt/types";

const errorInterceptor: Plugin = (context: Context) => {
  context.$axios.onError((error) => {
    const code =
      error?.response?.data?.error?.code || error?.response?.data?.code || "--";
    if (code === "TokenExpiredError") {
      context.store.dispatch("refreshToken");
    } else if (code === "UserNotConfirmedException") {
      if (process.client && window && window.location) {
        window.location.hash = "";
      }
      context.redirect("/auth/confirm/registration");
    }
  });
};

export default errorInterceptor;
