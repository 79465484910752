import type { NuxtAxiosInstance } from "@nuxtjs/axios";
import { Subscription } from "~/types/payment";

export const createSession =
  ($axios: NuxtAxiosInstance) =>
  async (type: Subscription, deviceId: string) => {
    return await $axios.$post(
      `/payments/create-session`,
      { type, deviceId },
      {
        headers: {
          Accept: "application/json",
          Content: "application/json",
        },
      }
    );
  };

export const getInvoices = ($axios: NuxtAxiosInstance) => async () => {
  return await $axios.$get(`/payments/invoices`, {
    headers: {
      Accept: "application/json",
      Content: "application/json",
    },
  });
};
