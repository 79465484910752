import config from "./config.json";
import themeConfig from "./theme.json";

export const title = config.title;
export const description = config.description;
export const shareUrl = config.shareUrl;
export const shareImage = config.shareImage;
export const facebookUrl = config.facebookUrl;
export const whatsappUrl = config.whatsappUrl;
export const twitterUrl = config.twitterUrl;
export const pinterestUrl = config.pinterestUrl;
export const paymentModuleActive = config.modules.payment;
export const managedAuthModuleActive = config.modules.managedAuth;
export const ssoModuleActive = config.modules.sso;
export const checkoutModuleActive = config.modules.checkout;
export const theme = themeConfig.theme;
export const fonts = themeConfig.fonts;
export const chart = themeConfig.chart;
