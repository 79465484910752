const middleware = {}

middleware['authenticated'] = require('../middleware/authenticated.js')
middleware['authenticated'] = middleware['authenticated'].default || middleware['authenticated']

middleware['locale'] = require('../middleware/locale.ts')
middleware['locale'] = middleware['locale'].default || middleware['locale']

middleware['notAuthenticated'] = require('../middleware/notAuthenticated.js')
middleware['notAuthenticated'] = middleware['notAuthenticated'].default || middleware['notAuthenticated']

middleware['projectId'] = require('../middleware/projectId.ts')
middleware['projectId'] = middleware['projectId'].default || middleware['projectId']

middleware['publicProjectId'] = require('../middleware/publicProjectId.ts')
middleware['publicProjectId'] = middleware['publicProjectId'].default || middleware['publicProjectId']

middleware['refreshToken'] = require('../middleware/refreshToken.js')
middleware['refreshToken'] = middleware['refreshToken'].default || middleware['refreshToken']

export default middleware
