import { GetterTree, ActionTree, MutationTree } from "vuex";
import amplitude from "amplitude-js";
import { $sentryReady } from "../shared/accessors/sentry";
import { User } from "~/types/user";
import { getAmplitudeUserId } from "~/shared";

const getDefaultState = () => ({} as {});

export const state = () => getDefaultState();

export type RootState = ReturnType<typeof state>;

export const getters: GetterTree<RootState, RootState> = {
  cookiesAccepted() {
    if (process.browser) {
      return localStorage.getItem("GDPR:accepted") === "true";
    }
    return false;
  },
};

export const mutations: MutationTree<RootState> = {
  resetState(state) {
    Object.assign(state, getDefaultState());
  },
};

export const actions: ActionTree<RootState, RootState> = {
  onAcceptCookies({ dispatch }, reload = false) {
    if (process.browser) {
      dispatch("acceptCookies");
      dispatch("loadGdprScripts");
      if (reload) location.reload();
    }
  },

  acceptCookies() {
    if (process.browser) {
      localStorage.setItem("GDPR:accepted", "true");
    }
  },

  loadGdprScripts({ rootGetters }) {
    const user: User | null = rootGetters.user;

    if (process.client) {
      amplitude.getInstance().setOptOut(false);
    }
    if (user != null) {
      identifySentryUser(user);
      if (process.client) {
        identifyAmplitudeUser(user, rootGetters);
      }
    }
  },
};

function identifySentryUser(user: User) {
  if (!$sentryReady) return;
  $sentryReady().then(($sentry) => {
    if ($sentry) {
      $sentry.setUser({ id: user.id.toString(), email: user.email });
    }
  });
}

function identifyAmplitudeUser(user: User, rootGetters: any) {
  amplitude.getInstance().setUserId(getAmplitudeUserId(user.id));
  const identify = new amplitude.Identify()
    .set("lang", user.lang)
    .set("subscription_type", user.subscription_type)
    .set("tour_finished", user.tour_finished)
    .set("accepted_commercial", user.accepted_commercial)
    .set("deleted", false)
    .set("avg_num_products", rootGetters["projects/avgNumProducts"])
    .set("num_projects", rootGetters["projects/projects"].length)
    .set("max_num_products", rootGetters["projects/maxNumProducts"]);
  amplitude.getInstance().identify(identify);
}
