//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { validateAll } from "~/shared/validators";
import { parseEmoji } from "~/shared";
export default {
  name: "BaseInputComponent",
  props: {
    label: {
      type: String,
      description: "Input label (text before input)",
      default: null,
    },
    labelEmoji: {
      type: String,
      description: "Input label emoji (emoji before label)",
      default: null,
    },
    rules: {
      type: Array,
      description: "Validation rules",
      default: () => {},
    },
    name: {
      type: String,
      description: "Input name (used for validation)",
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    value: {
      type: String,
      default: null,
    },
    formControlClasses: {
      type: String,
      default: "",
    },
    height: {
      type: String,
      default: "h-24",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      inputData: null,
      errors: [],
      validated: false,
      invalid: false,
    };
  },
  computed: {
    listeners() {
      return {
        ...this.$listeners,
        input: this.updateValue,
      };
    },
    classStr() {
      return this.invalid
        ? "input input-bordered input-error " + this.height
        : "input input-bordered " + this.height;
    },
  },
  watch: {
    value(newVal) {
      this.inputData = newVal;
    },
    inputData(newVal) {
      if (newVal === null) {
        return;
      }
      this.errors = validateAll(newVal, this.rules, this.name);
      this.validated = true;
      this.invalid = this.errors && this.errors.length > 0;
      this.$emit("invalid", this.invalid);
    },
  },
  mounted() {
    this.inputData = this.value;
  },
  methods: {
    parseEmoji(emoji) {
      return parseEmoji(emoji, this);
    },
    updateValue(evt) {
      const value = evt.target.value;
      this.$emit("input", value);
    },
  },
};
