import { Store } from "vuex";
import { Plugin } from "@nuxt/types";

const showPaymentBlockerFunc =
  ($store: Store<any>) => (heading: string, btn: string) => {
    if (process.browser && window) {
      $store.commit("payments/setPaymentBlocker", {
        heading,
        btn,
      });
      window.location.hash = "payment-blocker";
    }
  };

const showPaymentBlocker: Plugin = (ctx, inject) => {
  inject("showPaymentBlocker", showPaymentBlockerFunc(ctx.store));
};

export default showPaymentBlocker;
