import { GetterTree, ActionTree, MutationTree } from "vuex";
import amplitude from "amplitude-js";
import { Subscription, UserInvoice } from "~/types/payment";
import { PaymentSessionResponse, UserInvoicesResponse } from "~/types/response";
import { getLanguage } from "~/shared";
import { createSession, getInvoices } from "~/adapters/payments";
import { handleError } from "~/shared/errorHandler";

const getDefaultState = () =>
  ({
    paymentSessionId: null,
    invoices: [],
    paymentBlocker: { heading: null, btn: null },
  } as {
    paymentSessionId: string | null;
    invoices: UserInvoice[];
    paymentBlocker: {
      heading: string | null;
      btn: string | null;
    };
  });

export const state = () => getDefaultState();

export type RootState = ReturnType<typeof state>;

export const getters: GetterTree<RootState, RootState> = {
  paymentBlocker: (state) => {
    return state.paymentBlocker;
  },

  invoices: (state) => {
    return state.invoices;
  },

  paymentSessionId: (state) => {
    return state.paymentSessionId;
  },
};

export const mutations: MutationTree<RootState> = {
  setPaymentBlocker(
    state,
    paymentBlocker: {
      heading: string | null;
      btn: string | null;
    }
  ) {
    state.paymentBlocker = paymentBlocker;
  },

  setPaymentSessionId(state, sessionId: string | null) {
    state.paymentSessionId = sessionId;
  },

  setInvoices(state, invoices: UserInvoice[]) {
    state.invoices = invoices;
  },
};

export const actions: ActionTree<RootState, RootState> = {
  async createSession({ commit }, type: Subscription) {
    try {
      if (process.client) {
        const response: PaymentSessionResponse = await createSession(
          this.$axios
        )(type, amplitude.getInstance().getDeviceId());

        commit("setPaymentSessionId", response.sessionId);

        amplitude.getInstance().logEvent("createPaymentSession");
      }
    } catch (err) {
      return handleError(
        err,
        getLanguage(this.$i18n.locale),
        undefined,
        {
          file: "store/payments",
          method: "createSession",
        },
        true
      );
    }
  },

  async getInvoices({ commit }) {
    try {
      const response: UserInvoicesResponse = await getInvoices(this.$axios)();
      commit("setInvoices", response.invoices);
    } catch (err) {
      return handleError(
        err,
        getLanguage(this.$i18n.locale),
        undefined,
        {
          file: "store/payments",
          method: "getInvoices",
        },
        true
      );
    }
  },
};
