import type { NuxtAxiosInstance } from "@nuxtjs/axios";
import { Lang } from "../types/locale";

export const sendFeedback =
  ($axios: NuxtAxiosInstance) =>
  async (data: {
    feedbackText: string;
    email?: string | null | undefined;
    lang?: Lang | null | undefined;
  }) => {
    const privatePath = data.email == null ? "private" : "public";

    return await $axios.$post(`/feedback/save/${privatePath}`, data, {
      headers: {
        Accept: "application/json",
        Content: "application/json",
      },
    });
  };
