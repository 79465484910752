import { Context } from "@nuxt/types";

export default async function (context: Context) {
  const projectId = Number(context.params.project);

  if (!(Number.isInteger(projectId) && projectId > 0)) {
    return context.redirect("/mein-vanausbau/dashboard");
  }

  if (process.server) {
    const errorMessage = await context.store.dispatch(
      "projects/fetchProject",
      projectId
    );

    if (errorMessage) {
      return context.redirect("/mein-vanausbau/dashboard");
    }
  }
}
