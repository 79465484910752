export const ButtonsFacebookButton = () => import('../../components/buttons/FacebookButton.vue' /* webpackChunkName: "components/buttons-facebook-button" */).then(c => wrapFunctional(c.default || c))
export const ButtonsPaymentButton = () => import('../../components/buttons/PaymentButton.vue' /* webpackChunkName: "components/buttons-payment-button" */).then(c => wrapFunctional(c.default || c))
export const ButtonsPinterestButton = () => import('../../components/buttons/PinterestButton.vue' /* webpackChunkName: "components/buttons-pinterest-button" */).then(c => wrapFunctional(c.default || c))
export const ButtonsTwitterButton = () => import('../../components/buttons/TwitterButton.vue' /* webpackChunkName: "components/buttons-twitter-button" */).then(c => wrapFunctional(c.default || c))
export const ButtonsWhatsappButton = () => import('../../components/buttons/WhatsappButton.vue' /* webpackChunkName: "components/buttons-whatsapp-button" */).then(c => wrapFunctional(c.default || c))
export const CardsPricingCard = () => import('../../components/cards/PricingCard.vue' /* webpackChunkName: "components/cards-pricing-card" */).then(c => wrapFunctional(c.default || c))
export const ChartsDoughnutChart = () => import('../../components/charts/DoughnutChart.js' /* webpackChunkName: "components/charts-doughnut-chart" */).then(c => wrapFunctional(c.default || c))
export const ChartsLineChart = () => import('../../components/charts/LineChart.js' /* webpackChunkName: "components/charts-line-chart" */).then(c => wrapFunctional(c.default || c))
export const ChartsConfig = () => import('../../components/charts/config.js' /* webpackChunkName: "components/charts-config" */).then(c => wrapFunctional(c.default || c))
export const ChartsOptionHelpers = () => import('../../components/charts/optionHelpers.js' /* webpackChunkName: "components/charts-option-helpers" */).then(c => wrapFunctional(c.default || c))
export const CoreBaseCheckbox = () => import('../../components/core/BaseCheckbox.vue' /* webpackChunkName: "components/core-base-checkbox" */).then(c => wrapFunctional(c.default || c))
export const CoreBaseInput = () => import('../../components/core/BaseInput.vue' /* webpackChunkName: "components/core-base-input" */).then(c => wrapFunctional(c.default || c))
export const CoreBaseLoader = () => import('../../components/core/BaseLoader.vue' /* webpackChunkName: "components/core-base-loader" */).then(c => wrapFunctional(c.default || c))
export const CoreBaseTextarea = () => import('../../components/core/BaseTextarea.vue' /* webpackChunkName: "components/core-base-textarea" */).then(c => wrapFunctional(c.default || c))
export const CoreResponsiveImg = () => import('../../components/core/ResponsiveImg.vue' /* webpackChunkName: "components/core-responsive-img" */).then(c => wrapFunctional(c.default || c))
export const Error404 = () => import('../../components/error/Error404.vue' /* webpackChunkName: "components/error404" */).then(c => wrapFunctional(c.default || c))
export const Error500 = () => import('../../components/error/Error500.vue' /* webpackChunkName: "components/error500" */).then(c => wrapFunctional(c.default || c))
export const LayoutCookieConsent = () => import('../../components/layout/CookieConsent.vue' /* webpackChunkName: "components/layout-cookie-consent" */).then(c => wrapFunctional(c.default || c))
export const LayoutFeedbackButton = () => import('../../components/layout/FeedbackButton.vue' /* webpackChunkName: "components/layout-feedback-button" */).then(c => wrapFunctional(c.default || c))
export const LayoutFooter = () => import('../../components/layout/Footer.vue' /* webpackChunkName: "components/layout-footer" */).then(c => wrapFunctional(c.default || c))
export const LayoutNavbar = () => import('../../components/layout/Navbar.vue' /* webpackChunkName: "components/layout-navbar" */).then(c => wrapFunctional(c.default || c))
export const ModalsDeleteAccountModal = () => import('../../components/modals/DeleteAccountModal.vue' /* webpackChunkName: "components/modals-delete-account-modal" */).then(c => wrapFunctional(c.default || c))
export const ModalsDeleteProjectModal = () => import('../../components/modals/DeleteProjectModal.vue' /* webpackChunkName: "components/modals-delete-project-modal" */).then(c => wrapFunctional(c.default || c))
export const ModalsExportProgressModal = () => import('../../components/modals/ExportProgressModal.vue' /* webpackChunkName: "components/modals-export-progress-modal" */).then(c => wrapFunctional(c.default || c))
export const ModalsFeedbackModal = () => import('../../components/modals/FeedbackModal.vue' /* webpackChunkName: "components/modals-feedback-modal" */).then(c => wrapFunctional(c.default || c))
export const ModalsFurnitureConstructionModal = () => import('../../components/modals/FurnitureConstructionModal.vue' /* webpackChunkName: "components/modals-furniture-construction-modal" */).then(c => wrapFunctional(c.default || c))
export const ModalsLoginModal = () => import('../../components/modals/LoginModal.vue' /* webpackChunkName: "components/modals-login-modal" */).then(c => wrapFunctional(c.default || c))
export const ModalsPaymentBlockerModal = () => import('../../components/modals/PaymentBlockerModal.vue' /* webpackChunkName: "components/modals-payment-blocker-modal" */).then(c => wrapFunctional(c.default || c))
export const ModalsPaymentCancelModal = () => import('../../components/modals/PaymentCancelModal.vue' /* webpackChunkName: "components/modals-payment-cancel-modal" */).then(c => wrapFunctional(c.default || c))
export const ModalsPaymentSuccessModal = () => import('../../components/modals/PaymentSuccessModal.vue' /* webpackChunkName: "components/modals-payment-success-modal" */).then(c => wrapFunctional(c.default || c))
export const ModalsProductDetailsModal = () => import('../../components/modals/ProductDetailsModal.vue' /* webpackChunkName: "components/modals-product-details-modal" */).then(c => wrapFunctional(c.default || c))
export const ModalsResetPasswordModal = () => import('../../components/modals/ResetPasswordModal.vue' /* webpackChunkName: "components/modals-reset-password-modal" */).then(c => wrapFunctional(c.default || c))
export const ModalsSelectPlanModal = () => import('../../components/modals/SelectPlanModal.vue' /* webpackChunkName: "components/modals-select-plan-modal" */).then(c => wrapFunctional(c.default || c))
export const ShowcaseNavbar = () => import('../../components/showcase/ShowcaseNavbar.vue' /* webpackChunkName: "components/showcase-navbar" */).then(c => wrapFunctional(c.default || c))
export const ShowcaseRendererContainer = () => import('../../components/showcase/ShowcaseRendererContainer.vue' /* webpackChunkName: "components/showcase-renderer-container" */).then(c => wrapFunctional(c.default || c))
export const TablesSelectedProductsTable = () => import('../../components/tables/SelectedProductsTable.vue' /* webpackChunkName: "components/tables-selected-products-table" */).then(c => wrapFunctional(c.default || c))
export const ThreeContextMenu = () => import('../../components/three/ThreeContextMenu.vue' /* webpackChunkName: "components/three-context-menu" */).then(c => wrapFunctional(c.default || c))
export const ThreeMobileAlert = () => import('../../components/three/ThreeMobileAlert.vue' /* webpackChunkName: "components/three-mobile-alert" */).then(c => wrapFunctional(c.default || c))
export const ThreeNavbar = () => import('../../components/three/ThreeNavbar.vue' /* webpackChunkName: "components/three-navbar" */).then(c => wrapFunctional(c.default || c))
export const ThreeObjectList = () => import('../../components/three/ThreeObjectList.vue' /* webpackChunkName: "components/three-object-list" */).then(c => wrapFunctional(c.default || c))
export const ThreeRendererContainer = () => import('../../components/three/ThreeRendererContainer.vue' /* webpackChunkName: "components/three-renderer-container" */).then(c => wrapFunctional(c.default || c))
export const ThreeRightSidebar = () => import('../../components/three/ThreeRightSidebar.vue' /* webpackChunkName: "components/three-right-sidebar" */).then(c => wrapFunctional(c.default || c))
export const ThreeSettings = () => import('../../components/three/ThreeSettings.vue' /* webpackChunkName: "components/three-settings" */).then(c => wrapFunctional(c.default || c))
export const ThreeSidebar = () => import('../../components/three/ThreeSidebar.vue' /* webpackChunkName: "components/three-sidebar" */).then(c => wrapFunctional(c.default || c))
export const CoreSwatchesVCheck = () => import('../../components/core/swatches/VCheck.vue' /* webpackChunkName: "components/core-swatches-v-check" */).then(c => wrapFunctional(c.default || c))
export const CoreSwatchesVSwatch = () => import('../../components/core/swatches/VSwatch.vue' /* webpackChunkName: "components/core-swatches-v-swatch" */).then(c => wrapFunctional(c.default || c))
export const CoreSwatchesVSwatches = () => import('../../components/core/swatches/VSwatches.vue' /* webpackChunkName: "components/core-swatches-v-swatches" */).then(c => wrapFunctional(c.default || c))
export const ThreeDrawerDraggableProduct = () => import('../../components/three/drawer/DraggableProduct.vue' /* webpackChunkName: "components/three-drawer-draggable-product" */).then(c => wrapFunctional(c.default || c))
export const ThreeDrawerDraggableTexture = () => import('../../components/three/drawer/DraggableTexture.vue' /* webpackChunkName: "components/three-drawer-draggable-texture" */).then(c => wrapFunctional(c.default || c))
export const ThreeDrawer = () => import('../../components/three/drawer/ThreeDrawer.vue' /* webpackChunkName: "components/three-drawer" */).then(c => wrapFunctional(c.default || c))
export const ThreeFurnitureDrawer = () => import('../../components/three/drawer/ThreeFurnitureDrawer.vue' /* webpackChunkName: "components/three-furniture-drawer" */).then(c => wrapFunctional(c.default || c))
export const CoreSwatchesPresetsBasic = () => import('../../components/core/swatches/presets/basic.js' /* webpackChunkName: "components/core-swatches-presets-basic" */).then(c => wrapFunctional(c.default || c))
export const CoreSwatchesPresets = () => import('../../components/core/swatches/presets/index.js' /* webpackChunkName: "components/core-swatches-presets" */).then(c => wrapFunctional(c.default || c))
export const CoreSwatchesPresetsTextAdvanced = () => import('../../components/core/swatches/presets/text-advanced.js' /* webpackChunkName: "components/core-swatches-presets-text-advanced" */).then(c => wrapFunctional(c.default || c))
export const CoreSwatchesPresetsTextBasic = () => import('../../components/core/swatches/presets/text-basic.js' /* webpackChunkName: "components/core-swatches-presets-text-basic" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
