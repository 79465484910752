












































































import Vue from "vue";
import { Component, Prop } from "nuxt-property-decorator";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { User } from "~/types/user";
import BaseTextarea from "~/components/core/BaseTextarea.vue";
import { getLanguage, parseEmoji } from "~/shared";
import BaseInput from "~/components/core/BaseInput.vue";

@Component({
  name: "FeedbackModal",
  components: { BaseTextarea, BaseInput },
})
export default class FeedbackModal extends Vue {
  feedbackText: string | null = null;
  email: string | null = null;
  errorMessage: string | null = null;
  feedbackTextInvalid = true;
  emailInvalid = true;
  isLoading = false;

  @Prop({
    type: String,
    default: "Feedback",
  })
  heading!: string;

  get isAuth() {
    return this.$store.getters.isAuth as boolean;
  }

  get user(): User {
    return this.$store.getters.user;
  }

  get invalid() {
    if (this.isAuth) {
      return this.feedbackTextInvalid;
    }

    return this.feedbackTextInvalid && this.emailInvalid;
  }

  get faTimes() {
    return faTimes;
  }

  get airtableLink() {
    if (this.isAuth && this.user) {
      return (
        "https://airtable.com/shrPfalwogO49PBW8?prefill_Email=" +
        encodeURIComponent(this.user.email)
      );
    }

    return "https://airtable.com/shrPfalwogO49PBW8";
  }

  parseEmoji(emoji: string) {
    return parseEmoji(emoji, this);
  }

  onClose() {
    if (process.browser && window) {
      this.email = null;
      this.feedbackText = null;
      this.feedbackTextInvalid = false;
      this.emailInvalid = false;
      window.location.hash = "";
      this.$emit("close");
    }
  }

  async onSubmit() {
    if (!this.feedbackText || this.feedbackText.length <= 0) {
      this.errorMessage = this.$tc("components.modals.feedback.feedback_empty");
      return;
    }
    this.isLoading = true;

    const postData = this.isAuth
      ? { feedbackText: this.feedbackText }
      : {
          feedbackText: this.feedbackText,
          email: this.email,
          lang: getLanguage(this.$i18n.locale),
        };

    this.errorMessage = await this.$store.dispatch("sendFeedback", postData);

    if (this.errorMessage) {
      this.isLoading = false;
      return;
    }

    this.$notify({
      type: "success",
      message: this.$tc("components.modals.feedback.success_message"),
    });
    this.onClose();
    this.isLoading = false;
  }
}
