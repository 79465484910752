//
//
//
//
//

import error404 from "~/components/error/Error404.vue";
import error500 from "~/components/error/Error500.vue";

export default {
  name: "ErrorLayout",
  layout: "default",
  props: {
    error: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    errorPage() {
      if (this.error.statusCode === 404) {
        return error404;
      }

      return error500;
    },
  },
};
