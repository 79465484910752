














































































































































































































































































import Vue from "vue";
import { Component } from "nuxt-property-decorator";
import {
  faUserPlus,
  faSignInAlt,
  faAngleDown,
  faBus,
  faSignOutAlt,
  faCog,
  faTools,
  faDollarSign,
  faAddressCard,
  faTv,
  faQuestionCircle,
  faRoad,
  faCreditCard,
  faHandshake,
  faShuttleVan,
} from "@fortawesome/free-solid-svg-icons";
import { User } from "~/types/user";
import { paymentModuleActive } from "~/config";
import { parseEmoji } from "~/shared";

@Component({
  name: "NavbarComponent",
})
export default class Navbar extends Vue {
  get paymentModuleActive() {
    return paymentModuleActive;
  }

  get faTools() {
    return faTools;
  }

  get faShuttleVan() {
    return faShuttleVan;
  }

  get faDollarSign() {
    return faDollarSign;
  }

  get faHandshake() {
    return faHandshake;
  }

  get faQuestionCircle() {
    return faQuestionCircle;
  }

  get faAddressCard() {
    return faAddressCard;
  }

  get faTv() {
    return faTv;
  }

  get faUserPlus() {
    return faUserPlus;
  }

  get faSignInAlt() {
    return faSignInAlt;
  }

  get faCreditCard() {
    return faCreditCard;
  }

  get faAngleDown() {
    return faAngleDown;
  }

  get faBus() {
    return faBus;
  }

  get faSignOutAlt() {
    return faSignOutAlt;
  }

  get faCog() {
    return faCog;
  }

  get faRoad() {
    return faRoad;
  }

  get isAuth() {
    return this.$store.getters.isAuth as boolean;
  }

  get isPremium() {
    return (this.$store.getters.isPremium ||
      this.$store.getters.isUnlimited ||
      this.$store.getters.isPremiumV2 ||
      this.$store.getters.isLifelong) as boolean;
  }

  get user() {
    return this.$store.getters.user as User | null;
  }

  get imageLetters() {
    if (!this.user) return "";

    return this.user.username.slice(0, 2).toUpperCase();
  }

  parseEmoji(emoji: string) {
    return parseEmoji(emoji, this);
  }

  async onSignOut() {
    const errorMessage = await this.$store.dispatch("signOut");

    if (errorMessage) {
      this.$notify({ type: "error", message: errorMessage });
    }
  }
}
