import type { NuxtAxiosInstance } from "@nuxtjs/axios";
import { SelectedProduct } from "../types/project";

export const getProject =
  ($axios: NuxtAxiosInstance) => async (projectId: number) => {
    return await $axios.$get(`/projects/${projectId}`, {
      headers: {
        Accept: "application/json",
        Content: "application/json",
      },
    });
  };

export const getPublicProject =
  ($axios: NuxtAxiosInstance) => async (projectId: number) => {
    return await $axios.$get(`/projects/public/${projectId}`, {
      headers: {
        Accept: "application/json",
        Content: "application/json",
      },
    });
  };

export const getProjects = ($axios: NuxtAxiosInstance) => async () => {
  return await $axios.$get(`/projects`, {
    headers: {
      Accept: "application/json",
      Content: "application/json",
    },
  });
};

export const deleteProject =
  ($axios: NuxtAxiosInstance) => async (projectId: number) => {
    return await $axios.$delete(`/projects/${projectId}`, {
      headers: {
        Accept: "application/json",
        Content: "application/json",
      },
    });
  };

export const createProject =
  ($axios: NuxtAxiosInstance) =>
  async (data: {
    width: number;
    height: number;
    length: number;
    weight: number;
    image: string;
    title: string;
    model?: string | undefined | null;
  }) => {
    return await $axios.$post(`/projects`, data, {
      headers: {
        Accept: "application/json",
        Content: "application/json",
      },
    });
  };

export const createProjectFromTemplate =
  ($axios: NuxtAxiosInstance) =>
  async (data: {
    width: number;
    height: number;
    length: number;
    weight: number;
    image: string;
    title: string;
    model?: string | undefined | null;
    products: SelectedProduct[];
    ground_color: number;
    walls_color: number;
  }) => {
    return await $axios.$post(
      `/projects/template`,
      { ...data, groundColor: data.ground_color, wallColor: data.walls_color },
      {
        headers: {
          Accept: "application/json",
          Content: "application/json",
        },
      }
    );
  };

export const updateProjectProducts =
  ($axios: NuxtAxiosInstance) =>
  async (
    projectId: number,
    data: {
      products: SelectedProduct[] | null;
      groundColor: number;
      wallColor: number;
    }
  ) => {
    return await $axios.$patch(`/projects/products/${projectId}`, data, {
      headers: {
        Accept: "application/json",
        Content: "application/json",
      },
    });
  };

export const updateProjectVan =
  ($axios: NuxtAxiosInstance) =>
  async (
    projectId: number,
    data: {
      width: number;
      height: number;
      length: number;
      weight: number;
      image: string;
      title: string;
      model?: string | undefined | null;
    }
  ) => {
    return await $axios.$patch(`/projects/van/${projectId}`, data, {
      headers: {
        Accept: "application/json",
        Content: "application/json",
      },
    });
  };
