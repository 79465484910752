import { Shape } from "../types/project";
import { Lang } from "../types/locale";
import { TopCategory } from "../types/category";

/**
 * OTHER FILES THAT REQUIRE TRANSLATION
 * - shares/validators
 * - api/shared/response/errorCodes
 * - api/shared/response/successCodes
 */

export const categoryDe: { [category: string]: string } = {
  [TopCategory.VAN]: "Van",
  [TopCategory.WALLS]: "Wände",
  [TopCategory.GROUND]: "Boden",
  [TopCategory.KITCHEN]: "Küche",
  [TopCategory.SANITARY]: "Sanitär",
  [TopCategory.FURNITURE]: "Möbel",
  [TopCategory.WINDOWS]: "Fenster",
  [TopCategory.DECORATION]: "Deko",
  [TopCategory.LAYOUT]: "Grundriss",
};

export function getTypeNameEn(subcategory: string) {
  const subcategories: { [key: string]: string } = {
    "Elektrische Kühlbox": "Electric cooler",
    "Absorber Kühlschrank": "Absorber fridge",
    Kompressorkühlschrank: "Compressor fridge",
    Gasherd: "Gas stove",
    "Elektrischer Herd": "Electrical stove",
    Frischwassertank: "Fresh water tank",
    Abwassertank: "Waste water tank",
    "Fester Wasserhahn": "Fixed",
    "Ausziehbarer Wasserhahn": "Pull out",
    Eimertoilette: "Bucket toilet",
    Chemietoilette: "Chemical toilet",
    Trenntoilette: "Separating Toilet",
    Festtanktoilette: "Fix tank toilet",
    Ausstellfenster: "Fold-out window",
    Schiebefenster: "Sliding window",
    Matratze: "Mattress",
    Polster: "Cushion",
    Duschwanne: "Tray",
    Kühlbox: "Cooler",
  };

  return subcategories[subcategory];
}

export function getSubcategoryNameEn(subcategory: string) {
  const subcategories: { [key: string]: string } = {
    Kühlschrank: "Fridge",
    Herd: "Stove",
    Wassertank: "Watertank",
    Wasserhahn: "Faucet",
    Spülbecken: "Sink",
    Toilette: "Toilet",
    Innendusche: "Indoorshower",
    Fenster: "Window",
    Dachfenster: "Roof window",
    Basiselemente: "Base elements",
    Bett: "Bed",
    Stuhl: "Chair",
    Küche: "Kitchen",
    Stauraum: "Storage",
    Tisch: "Table",
    Griffe: "Handle",
    Tür: "Door",
    Regal: "Shelf",
  };

  return subcategories[subcategory];
}

export const shapeDe: { [shape: string]: string } = {
  [Shape.Wall]: "Wand",
  [Shape.Cube]: "Kasten",
  [Shape.Sphere]: "Kugel",
  [Shape.Cylinder]: "Zylinder",
  [Shape.Bed]: "Bett",
  [Shape.Chair]: "Stuhl",
  [Shape.Kitchen]: "Küche",
  [Shape.Storage]: "Stauraum 1 Schublade",
  [Shape.Storage1]: "Stauraum 1 Schublade",
  [Shape.Storage2]: "Stauraum 2 Schubladen",
  [Shape.Storage3]: "Stauraum 3 Schubladen",
  [Shape.Storage4]: "Stauraum 4 Schubladen",
  [Shape.Storage5]: "Stauraum 5 Schubladen",
  [Shape.Table]: "Tisch 4 Beine Eckige Platte",
  [Shape.TableRotatable]: "Drehbarer Tisch",
  [Shape.Table4LegsRound]: "Tisch 4 Beine Runde Platte",
  [Shape.Table1LegRound]: "Tisch 1 Bein Runde Platte",
  [Shape.Table4LegsSquare]: "Tisch 4 Beine Eckige Platte",
  [Shape.Table1LegSquare]: "Tisch 1 Bein Eckige Platte",
  [Shape.TableTopSquare]: "Tischplatte Eckig",
  [Shape.TableTopRound]: "Tischplatte Rund",
  [Shape.TableLeg]: "Tischbein",
  [Shape.TableLegRotatable]: "Rotierbares Tischbein",
  [Shape.Board]: "Wandregal",
  [Shape.Wheelcase]: "Radkasten",
  [Shape.DoorSilverKnob]: "Tür Silberner Türknauf",
  [Shape.DoorDarkKnob]: "Tür Dunkler Türknauf",
  [Shape.DoorSilverHandle]: "Tür Silberner Griff",
  [Shape.DoorDarkHandle]: "Tür Dunkler Griff",
  [Shape.DoorSilverPull]: "Schiebetür Silberner Griff",
  [Shape.DoorDarkPull]: "Schiebetür Dunkler Griff",
  [Shape.DriverSeat]: "Fahrersitz",
  [Shape.DriverBench]: "Fahrerbank",
};

export function getCorsErrorMessage(lang: Lang): string {
  return lang === Lang.EN
    ? "The server is currently not reachable. The administrator is notified. Please try again tomorrow."
    : "Der Server ist gerade nicht erreichbar. Der Administrator wird benachrichtigt. Bitte versuche es morgen erneut.";
}

export function getInternetOfflineErrorMessage(lang: Lang): string {
  return lang === Lang.EN
    ? "You are offline and can't connect to the server."
    : "Du bist offline und kannst dich nicht mit dem Server verbinden.";
}

export function getDefaulErrorMessage(lang: Lang): string {
  return lang === Lang.EN
    ? "This should not happen. Please write an email to danielundmeike@plan-your-van.com and we will help you."
    : "Das sollte nicht passieren. Schreibe bitte eine Email an danielundmeike@plan-your-van.com und wir helfen dir.";
}
