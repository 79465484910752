export const author = "Daniel von Mirbach";
export const defaultVanImage = "https://cdn.plan-your-van.com/camper.png";
export const GROUND_UUID = "808b1cf5-c954-49fb-b514-7859aecc61fd";
export const ROOF_UUID = "a95a0437-cce7-4a15-8912-0e3d81e5c91b";
export const FRONT_UUID = "539a8116-eb5f-4aff-ab57-2e4322bce880";
export const BACK_UUID = "9bb8d145-fbcd-4ab3-93a7-ad1040e65d7e";
export const RIGHT_UUID = "628ed3d7-7275-4063-8f95-4c0dd46d6d4d";
export const LEFT_UUID = "d8c872ec-aa82-454d-91a2-db42b96d5ed9";
export const WALL_UUIDs = [
  GROUND_UUID,
  ROOF_UUID,
  FRONT_UUID,
  BACK_UUID,
  RIGHT_UUID,
  LEFT_UUID,
];
export const ignoreErrors = [
  "Non-Error exception captured",
  "Non-Error promise rejection captured",
  "sw.js",
  "ENOENT: no such file or directory, open",
  "Cannot set headers after they are sent to the client",
  "ResizeObserver loop limit exceeded",
  "Non-Error exception captured",
];
