import type { NuxtAxiosInstance } from "@nuxtjs/axios";
import { Lang } from "../types/locale";
import { Settings } from "../types/user";

export const fetchUser = ($axios: NuxtAxiosInstance) => async () => {
  return await $axios.$get("/users", {
    headers: {
      Accept: "application/json",
      Content: "application/json",
    },
  });
};

export const changeLanguage =
  ($axios: NuxtAxiosInstance) => async (lang: Lang) => {
    const data = { lang };

    return await $axios.$patch("/users/lang", data, {
      headers: {
        Accept: "application/json",
        Content: "application/json",
      },
    });
  };

export const changeSettings =
  ($axios: NuxtAxiosInstance) => async (settings: Settings) => {
    const data = { settings };

    return await $axios.$patch("/users/settings", data, {
      headers: {
        Accept: "application/json",
        Content: "application/json",
      },
    });
  };

export const finishTour = ($axios: NuxtAxiosInstance) => async () => {
  return await $axios.$patch(
    "/users/finish-tour",
    {},
    {
      headers: {
        Accept: "application/json",
        Content: "application/json",
      },
    }
  );
};

export const setShowedFeedback = ($axios: NuxtAxiosInstance) => async () => {
  return await $axios.$patch(
    "/users/showed-feedback",
    {},
    {
      headers: {
        Accept: "application/json",
        Content: "application/json",
      },
    }
  );
};

export const acceptCommercial = ($axios: NuxtAxiosInstance) => async () => {
  return await $axios.$patch(
    "/users/accept-commercial",
    { acceptCommercial: true },
    {
      headers: {
        Accept: "application/json",
        Content: "application/json",
      },
    }
  );
};

export const unacceptCommercial = ($axios: NuxtAxiosInstance) => async () => {
  return await $axios.$patch(
    "/users/accept-commercial",
    { acceptCommercial: false },
    {
      headers: {
        Accept: "application/json",
        Content: "application/json",
      },
    }
  );
};
