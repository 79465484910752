

























































import Vue from "vue";
import { Component } from "nuxt-property-decorator";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import BaseInput from "~/components/core/BaseInput.vue";

@Component({
  name: "ResetPasswordComponent",
  components: { BaseInput },
})
export default class ResetPasswordModal extends Vue {
  email: string | null = null;
  errorMessage: string | null = null;
  emailInvalid = true;
  isLoading = false;

  get invalid() {
    return this.emailInvalid;
  }

  get faTimes() {
    return faTimes;
  }

  onClose() {
    if (process.browser && window) {
      window.location.hash = "";
    }
  }

  async onSubmit() {
    if (!this.email) {
      return;
    }

    this.isLoading = true;

    this.errorMessage = await this.$store.dispatch("resetPassword", this.email);

    if (this.errorMessage) {
      this.isLoading = false;
      return;
    }

    this.onClose();

    await this.$router.push("/auth/confirm/password/" + this.email);

    this.isLoading = false;
  }
}
