import url from "url";
import { Request } from "express";
import { GetterTree, ActionTree, MutationTree } from "vuex";
import mobile from "is-mobile";
import amplitude from "amplitude-js";
import { NuxtCookies } from "cookie-universal-nuxt";
import { Settings, User } from "../types/user";
import { Lang } from "../types/locale";
import { log } from "../shared/logger";
import {
  getAmplitudeUserId,
  getDateDiffInDays,
  getLanguage,
  userLangToI18nLang,
} from "../shared";
import { handleError } from "../shared/errorHandler";
import {
  confirmRegistration,
  confirmResetPassword,
  deleteUser,
  resendConfirmCode,
  resetPassword,
  signIn,
  signOut,
  signUp,
  refreshToken,
} from "~/adapters/auth";
import {
  IAuthResponse,
  IRefreshTokenResponse,
  LoginResponse,
  UserResponse,
} from "~/types/response";
import {
  acceptCommercial,
  changeLanguage,
  changeSettings,
  fetchUser,
  finishTour,
  setShowedFeedback,
  unacceptCommercial,
} from "~/adapters/user";
import { sendFeedback } from "~/adapters/feedback";
import { Subscription } from "~/types/payment";

const cookieparser = require("cookieparser");

const getDefaultState = () =>
  ({
    auth: null,
    user: null,
    isMobile: false,
    showProductDetailsModal: false,
  } as {
    auth: IAuthResponse | any;
    user: User | null;
    isMobile: boolean;
    showProductDetailsModal: boolean;
  });

export const state = () => getDefaultState();

export type RootState = ReturnType<typeof state>;

export const getters: GetterTree<RootState, RootState> = {
  auth: (state) => {
    return state.auth;
  },

  isAuth: (state) => {
    return (
      state.auth && new Date(state.auth.jwt_expired) >= new Date(Date.now())
    );
  },

  user: (state) => {
    return state.user;
  },

  isPremium: (state) => {
    return state.user && state.user.subscription_type === Subscription.PREMIUM;
  },

  isLifelong: (state) => {
    return state.user && state.user.subscription_type === Subscription.LIFELONG;
  },

  isPremiumV2: (state) => {
    return (
      state.user && state.user.subscription_type === Subscription.PREMIUMV2
    );
  },

  isUnlimited: (state) => {
    return (
      state.user && state.user.subscription_type === Subscription.UNLIMITED
    );
  },

  isFree: (state) => {
    return (
      !(state.user && state.user.subscription_type === Subscription.PREMIUM) &&
      !(state.user && state.user.subscription_type === Subscription.LIFELONG) &&
      !(
        state.user && state.user.subscription_type === Subscription.PREMIUMV2
      ) &&
      !(state.user && state.user.subscription_type === Subscription.UNLIMITED)
    );
  },

  isMobile: (state) => {
    return state.isMobile;
  },

  showProductDetailsModal: (state) => {
    return state.showProductDetailsModal;
  },

  showFeedback: (state) => {
    if (state.user == null || state.user.showed_feedback) {
      return false;
    }

    const daysBetween = getDateDiffInDays(
      new Date(),
      new Date(state.user.created_at)
    );

    return daysBetween > 2;
  },
};

export const mutations: MutationTree<RootState> = {
  resetState(state) {
    Object.assign(state, getDefaultState());
  },

  clearAuth(state, redirect) {
    state.auth = null;
    redirect("/");
  },

  setAuth(state, auth: IAuthResponse, error?: Error) {
    state.auth = auth;
    if (error) {
      log("[STORE][INDEX][MUTATION] setAuth", error);
    }
  },

  setUser(state, user: User | null) {
    state.user = user;
  },

  setShowProductDetailsModal(state, show: boolean) {
    state.showProductDetailsModal = show;
  },

  setIsMobile(state, pIsMobile: boolean) {
    state.isMobile = pIsMobile;
  },
};

export const actions: ActionTree<RootState, RootState> = {
  nuxtServerInit:
    process.server && !process.static
      ? async function ({ commit }, { req }: { req: Request | undefined }) {
          const isMobile = mobile({ tablet: true, ua: req });

          commit("setIsMobile", isMobile);

          if (!req || !req.headers.cookie) {
            return;
          }

          setReferrerCookie(this.$cookies, req);

          const parsedCookie = cookieparser.parse(req.headers.cookie);

          if (parsedCookie.token) {
            commit("resetState");
            commit("three/resetState");
            commit("cookies/resetState");
            commit("projects/resetState");
          }

          try {
            const response: IRefreshTokenResponse = await refreshToken(
              this.$axios
            )();
            commit("setAuth", response.auth);
            commit("setUser", response.user);
            commit("three/setSettings", response.user.settings);
          } catch (err: any) {
            commit("setAuth", null, err);
            commit("setUser", null);
            commit("three/resetState");
            commit("cookies/resetState");
            commit("projects/resetState");
          }
        }
      : () => {},

  nuxtClientInit: ({ commit, getters, dispatch }) => {
    if (process.env.AMPLITUDE_API_KEY) {
      amplitude.getInstance().init(process.env.AMPLITUDE_API_KEY);
    }

    const isMobile = mobile({ tablet: true, featureDetect: true });
    commit("setIsMobile", isMobile);

    if (getters.isAuth || getters["cookies/cookiesAccepted"]) {
      dispatch("cookies/loadGdprScripts");
    } else {
      amplitude.getInstance().setOptOut(true);
    }
  },

  clearAuth({ commit }, redirect) {
    commit("clearAuth", redirect);
    commit("setUser", null);
    commit("three/resetState");
    commit("cookies/resetState");
    commit("projects/resetState");
  },

  async signOut({ commit }) {
    try {
      await signOut(this.$axios)(getLanguage(this.$i18n.locale));

      commit("resetState");
      commit("three/resetState");
      commit("cookies/resetState");
      commit("projects/resetState");

      if (process.client) {
        amplitude.getInstance().logEvent("signOut");
      }
      this.$router.push("/");
    } catch (err: any) {
      return handleError(
        err,
        getLanguage(this.$i18n.locale),
        undefined,
        {
          file: "store",
          method: "signOut",
        },
        true
      );
    }
  },

  async refreshToken({ commit, state }) {
    if (state.auth) {
      if (new Date(Date.now()) > new Date(state.auth.jwt_expired)) {
        try {
          const response: IRefreshTokenResponse = await refreshToken(
            this.$axios
          )();
          commit("setAuth", response.auth);
          commit("setUser", response.user);
          commit("three/setSettings", response.user.settings);
        } catch (err: any) {
          commit("setAuth", null, err);
          commit("setUser", null);
          commit("three/resetState");
          commit("cookies/resetState");
          commit("projects/resetState");
        }
      }
    } else {
      commit("resetState");
      commit("three/resetState");
      commit("cookies/resetState");
      commit("projects/resetState");
    }
  },

  async fetchUser({ commit }) {
    try {
      const res: UserResponse = await fetchUser(this.$axios)();
      commit("setUser", res.user);
      commit("three/setSettings", res.user.settings);
    } catch (err: any) {
      return handleError(err, getLanguage(this.$i18n.locale), undefined, {
        file: "store",
        method: "fetchUser",
      });
    }
  },

  async signIn(
    { commit, dispatch },
    payload: { email: string; password: string }
  ) {
    try {
      const { email, password } = payload;

      if (!email || !password) {
        return;
      }

      const res: LoginResponse = await signIn(this.$axios)(
        email,
        password,
        getLanguage(this.$i18n.locale)
      );

      const auth = res.auth;

      commit("setAuth", auth);
      commit("setUser", res.user);
      commit("three/setSettings", res.user.settings);

      await dispatch("cookies/acceptCookies");
      await dispatch("cookies/loadGdprScripts");

      if (process.client) {
        amplitude.getInstance().setUserId(getAmplitudeUserId(res.user.id));
        amplitude.getInstance().logEvent("signIn");
      }
    } catch (err: any) {
      commit("three/resetState");
      commit("cookies/resetState");
      commit("projects/resetState");

      return handleError(err, getLanguage(this.$i18n.locale), undefined, {
        file: "store",
        method: "signIn",
      });
    }
  },

  async signUp({ dispatch }, payload: { email: string; password: string }) {
    try {
      const { email, password } = payload;

      if (!email || !password) {
        return;
      }

      const res: UserResponse = await signUp(this.$axios)(
        email,
        password,
        getLanguage(this.$i18n.locale)
      );

      await dispatch("cookies/acceptCookies");
      await dispatch("cookies/loadGdprScripts");

      if (process.client) {
        amplitude.getInstance().setUserId(getAmplitudeUserId(res.user.id));
        amplitude.getInstance().logEvent("signUp");

        const identify = new amplitude.Identify().set(
          "signup_intent_created",
          true
        );
        amplitude.getInstance().identify(identify);
      }
    } catch (err: any) {
      return handleError(err, getLanguage(this.$i18n.locale), undefined, {
        file: "store",
        method: "signUp",
      });
    }
  },

  async confirmRegistration(
    { dispatch },
    payload: { email: string; code: string; agreeCommercial: boolean }
  ) {
    try {
      const { email, code, agreeCommercial } = payload;

      if (!email || !code) {
        return;
      }

      const res: UserResponse = await confirmRegistration(this.$axios)({
        email,
        code,
        lang: getLanguage(this.$i18n.locale),
        acceptCommercial: agreeCommercial,
      });

      await dispatch("cookies/acceptCookies");
      await dispatch("cookies/loadGdprScripts");

      if (process.client) {
        amplitude.getInstance().setUserId(getAmplitudeUserId(res.user.id));
        amplitude.getInstance().logEvent("confirmSignUp");
        const identify = new amplitude.Identify().set("signup_confirmed", true);
        amplitude.getInstance().identify(identify);
      }
    } catch (err: any) {
      return handleError(err, getLanguage(this.$i18n.locale), undefined, {
        file: "store",
        method: "confirmRegistration",
      });
    }
  },

  async confirmResetPassword(
    _state,
    payload: { email: string; code: string; password: string }
  ) {
    try {
      const { email, code, password } = payload;

      if (!email || !code || !password) {
        return;
      }

      await confirmResetPassword(this.$axios)(
        email,
        code,
        password,
        getLanguage(this.$i18n.locale)
      );

      if (process.client) {
        amplitude.getInstance().logEvent("confirmResetPassword");
      }
    } catch (err: any) {
      return handleError(err, getLanguage(this.$i18n.locale), undefined, {
        file: "store",
        method: "confirmResetPassword",
      });
    }
  },

  async resendConfirmCode(_state, email: string) {
    try {
      if (!email) {
        return;
      }

      await resendConfirmCode(this.$axios)(
        email,
        getLanguage(this.$i18n.locale)
      );

      if (process.client) {
        amplitude.getInstance().logEvent("resendConfirmCode");
      }
    } catch (err: any) {
      return handleError(err, getLanguage(this.$i18n.locale), undefined, {
        file: "store",
        method: "resendConfirmCode",
      });
    }
  },

  async resetPassword(_state, email: string) {
    try {
      if (!email) {
        return;
      }

      await resetPassword(this.$axios)(email, getLanguage(this.$i18n.locale));

      if (process.client) {
        amplitude.getInstance().logEvent("resetPassword");
      }
    } catch (err: any) {
      return handleError(err, getLanguage(this.$i18n.locale), undefined, {
        file: "store",
        method: "resetPassword",
      });
    }
  },

  async deleteUser(_state, email: string) {
    try {
      if (!email) {
        return;
      }

      await deleteUser(this.$axios)(email);

      if (process.client) {
        amplitude.getInstance().logEvent("delete");
        const identify = new amplitude.Identify().set("deleted", true);
        amplitude.getInstance().identify(identify);
      }
    } catch (err: any) {
      return handleError(err, getLanguage(this.$i18n.locale), undefined, {
        file: "store",
        method: "deleteUser",
      });
    }
  },

  async changeLanguage({ commit }, lang: Lang) {
    try {
      if (!lang) {
        return;
      }

      const res: UserResponse = await changeLanguage(this.$axios)(lang);

      commit("setUser", res.user);
      commit("three/setSettings", res.user.settings);

      await this.$i18n.setLocale(userLangToI18nLang(lang));

      if (process.client) {
        amplitude.getInstance().logEvent("changeLanguage", { newLang: lang });
        const identify = new amplitude.Identify().set("lang", lang);
        amplitude.getInstance().identify(identify);
      }
    } catch (err: any) {
      return handleError(
        err,
        getLanguage(this.$i18n.locale),
        undefined,
        {
          file: "store",
          method: "changeLanguage",
        },
        true
      );
    }
  },

  updateSettings({ commit }, settings: Settings | null) {
    return new Promise((resolve) => {
      const queueRes = this.$queue.push(changeSettings(this.$axios), [
        settings,
      ]);

      if (!queueRes) return resolve(null);

      queueRes.then(
        (response: UserResponse) => {
          commit("setUser", response.user);
          commit("three/setSettings", response.user.settings);

          if (process.client) {
            amplitude
              .getInstance()
              .logEvent("updateSettings", { newSettings: settings });
          }

          resolve(null);
        },
        (err) => {
          const errorMessage = handleError(
            err,
            getLanguage(this.$i18n.locale),
            undefined,
            {
              file: "store",
              method: "updateSettings",
            },
            true
          );
          resolve(errorMessage);
        }
      );
    });
  },

  async finishTour({ commit }) {
    try {
      const response: UserResponse = await finishTour(this.$axios)();

      commit("setUser", response.user);
      commit("three/setSettings", response.user.settings);

      if (process.client) {
        amplitude.getInstance().logEvent("finishTour");
        const identify = new amplitude.Identify().set(
          "tour_finished",
          response.user.tour_finished
        );
        amplitude.getInstance().identify(identify);
      }
    } catch (err) {
      return handleError(
        err,
        getLanguage(this.$i18n.locale),
        undefined,
        {
          file: "store",
          method: "updateSettings",
        },
        true
      );
    }
  },

  async acceptCommercial({ commit }) {
    try {
      const res: UserResponse = await acceptCommercial(this.$axios)();

      commit("setUser", res.user);
      commit("three/setSettings", res.user.settings);

      if (process.client) {
        amplitude.getInstance().logEvent("acceptCommercial");
        const identify = new amplitude.Identify().set(
          "accepted_commercial",
          true
        );
        amplitude.getInstance().identify(identify);
      }
    } catch (err: any) {
      return handleError(
        err,
        getLanguage(this.$i18n.locale),
        undefined,
        {
          file: "store",
          method: "acceptCommercial",
        },
        true
      );
    }
  },

  async unacceptCommercial({ commit }) {
    try {
      const res: UserResponse = await unacceptCommercial(this.$axios)();

      commit("setUser", res.user);
      commit("three/setSettings", res.user.settings);

      if (process.client) {
        amplitude.getInstance().logEvent("unacceptCommercial");
        const identify = new amplitude.Identify().set(
          "accepted_commercial",
          false
        );
        amplitude.getInstance().identify(identify);
      }
    } catch (err: any) {
      return handleError(
        err,
        getLanguage(this.$i18n.locale),
        undefined,
        {
          file: "store",
          method: "unacceptCommercial",
        },
        true
      );
    }
  },

  async sendFeedback(
    _ctx,
    data: {
      feedbackText: string;
      email?: string | null | undefined;
      lang?: Lang | null | undefined;
    }
  ) {
    try {
      await sendFeedback(this.$axios)(data);

      if (process.client) {
        amplitude
          .getInstance()
          .logEvent("sendFeedback", { text: data.feedbackText });
      }
    } catch (err: any) {
      return handleError(
        err,
        getLanguage(this.$i18n.locale),
        undefined,
        {
          file: "store",
          method: "sendFeedback",
        },
        true
      );
    }
  },

  async setShowedFeedback({ commit }) {
    try {
      const response: UserResponse = await setShowedFeedback(this.$axios)();

      commit("setUser", response.user);
      commit("three/setSettings", response.user.settings);

      if (process.client) {
        amplitude.getInstance().logEvent("showedFeedback");
      }
    } catch (err) {
      return handleError(
        err,
        getLanguage(this.$i18n.locale),
        undefined,
        {
          file: "store",
          method: "setShowedFeedback",
        },
        true
      );
    }
  },
};

function setReferrerCookie($cookies: NuxtCookies, req: Request) {
  // eslint-disable-next-line node/no-deprecated-api
  const query = url.parse(req.url, true).query;
  const referrer = query.ref;
  if (typeof referrer === "string" && referrer !== "") {
    // eslint-disable-next-line no-console
    console.log(`Referred access. Referrer is ${referrer}`);
    $cookies.set("referrer", referrer, {
      expires: new Date(new Date().setFullYear(new Date().getFullYear() + 1)), // expires in 1 year
      httpOnly: true,
      secure: process.env.NODE_ENV === "production",
      sameSite: process.env.NODE_ENV === "production" ? "none" : "lax",
      domain:
        process.env.NODE_ENV === "production"
          ? ".plan-your-van.com"
          : undefined,
      path: "/",
    });
  }
}
