import Vue from "vue";
import CryptoJs from "crypto-js";
import { TopCategory } from "../types/category";
import { Lang } from "../types/locale";
import { Project, SelectedProduct } from "../types/project";
import { Product } from "~/types/product";

export function parseEmoji(emoji: any, ctx: Vue) {
  const self = ctx as any;
  return self.$twemoji.parse(emoji);
}

export function fallbackCopyTextToClipboard(text: string) {
  if (!process.client || !document) return;

  const textArea = document.createElement("textarea");
  textArea.value = text;

  // Avoid scrolling to bottom
  textArea.style.top = "0";
  textArea.style.left = "0";
  textArea.style.position = "fixed";

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    document.execCommand("copy");
  } catch (err) {}

  document.body.removeChild(textArea);
}

export function copyTextToClipboard(text: string) {
  if (!process.client || !document || !navigator) return;
  if (!navigator.clipboard) {
    fallbackCopyTextToClipboard(text);
    return;
  }
  navigator.clipboard.writeText(text);
}

export function userLangToI18nLang(lang: Lang) {
  switch (lang) {
    case Lang.DE:
      return "de";
    case Lang.EN:
      return "en";
    default:
      return "de";
  }
}

export function getLanguage(locale: string) {
  switch (locale) {
    case "de":
      return Lang.DE;
    case "en":
      return Lang.EN;
    default:
      return Lang.DE;
  }
}

export function getDateDiffInDays(date1: Date, date2: Date) {
  const diffTime = Math.abs(date2.getTime() - date1.getTime());
  return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
}

export function redirectAndTrack(url: string) {
  if (window) {
    window.open(url, "_blank")?.focus();
  }
}

export function getApiBaseUrl() {
  if (typeof process.env.API_BASE_URL === "string") {
    return process.env.API_BASE_URL;
  }

  if (typeof process.env.HEROKU_APP_NAME === "string") {
    return "/api";
  }

  return process.env.NODE_ENV === "production"
    ? process.env.DEV === "true"
      ? "https://dev.plan-your-van.com/api"
      : "https://plan-your-van.com/api"
    : "http://localhost:3000/api";
}

export function wait(ms = 2000) {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(null);
    }, ms);
  });
}

export function debounce<Params extends any[]>(
  func: (...args: Params) => any,
  timeout: number
): (...args: Params) => void {
  let timer: NodeJS.Timeout;
  return (...args: Params) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func(...args);
    }, timeout);
  };
}

export function getPrice(someNumber: number | undefined | null) {
  if (someNumber === null) {
    return "-";
  }
  if (someNumber == null && someNumber !== 0) {
    return "-";
  }

  const priceFormatter = new Intl.NumberFormat("de-DE", {
    style: "currency",
    currency: "EUR",
    minimumFractionDigits: 2,
  });

  return priceFormatter.format(someNumber);
}

export function getWeight(someNumber: number | undefined | null) {
  if (someNumber === null) {
    return "-";
  }
  if (someNumber == null && someNumber !== 0) {
    return "-";
  }

  const weightFormatter = new Intl.NumberFormat("de-DE", {
    style: "decimal",
    minimumFractionDigits: 2,
  } as any);

  const formattedWeight = weightFormatter.format(someNumber);

  return formattedWeight ? `${formattedWeight} kg` : "";
}

export function groupBy<T extends { [key: string]: any }>(
  arr: T[],
  key: string
): { [key: string]: T[] } {
  const byString = function (o: any, s: string) {
    s = s.replace(/\[(\w+)\]/g, ".$1"); // convert indexes to properties
    s = s.replace(/^\./, ""); // strip a leading dot
    const a = s.split(".");
    for (let i = 0, n = a.length; i < n; ++i) {
      const k = a[i];
      if (k in o) {
        o = o[k];
      } else {
        return;
      }
    }
    return o;
  };

  return arr.reduce((r, a) => {
    const ikey = byString(a, key);
    r[ikey] = r[ikey] || [];
    r[ikey].push(a);
    return r;
  }, Object.create(null));
}

export function getTotalWeight(
  arr: Array<{
    id: number;
    weight?: number | undefined | null;
    price?: number | undefined | null;
    topcategory: TopCategory;
    subcategory: string;
    width?: number | undefined | null;
    height?: number | undefined | null;
    length?: number | undefined | null;
    weightAmount: number;
  }>
) {
  const totalWeight = arr.reduce((prev, cur) => {
    if (cur.weight != null) {
      return prev + cur.weight * cur.weightAmount;
    }
    return 0;
  }, 0);
  return Number(totalWeight.toPrecision(4));
}

export function getTotalCost(
  arr: Array<{
    id: number;
    weight?: number | undefined | null;
    price?: number | undefined | null;
    topcategory: TopCategory;
    subcategory: string;
    width?: number | undefined | null;
    height?: number | undefined | null;
    length?: number | undefined | null;
    priceAmount: number;
  }>
) {
  const totalCost = arr.reduce((prev, cur) => {
    if (cur.price != null) {
      return prev + cur.price * cur.priceAmount;
    }
    return 0;
  }, 0);
  return Number(totalCost.toPrecision(4));
}

export function capitalizeFirstLetter(word: string) {
  return word.charAt(0).toUpperCase() + word.toLowerCase().slice(1);
}

export function hashHexToNumber(hex: string) {
  const transformedHex = hex.replace("#", "0x");
  return parseInt(transformedHex, 16);
}

export function numberToHashHex(num: number) {
  return "#" + (num & 0x00ffffff).toString(16).padStart(6, "0");
}

export function getFullTextLanguage(locale: string) {
  switch (locale) {
    case "de":
      return "Deutsch";
    case "en":
      return "English";
    default:
      return "Deutsch";
  }
}

export function radiansToDegrees(
  radians: number,
  discretized = false,
  discretizedTo = 10
) {
  return discretized
    ? (Math.round((radians * (180 / Math.PI)) / discretizedTo) *
        discretizedTo) %
        360
    : round(radians * (180 / Math.PI)) % 360;
}

export function degreesToRadians(degrees: number) {
  return round(degrees * (Math.PI / 180));
}

export function round(num: number, to = 2) {
  return parseFloat(num.toFixed(to));
}

export function discretize(num: number, to = 10) {
  return Math.round(num / to) * to;
}

export function randomIntFromInterval(min: number, max: number) {
  // min and max included
  return Math.floor(Math.random() * (max - min + 1) + min);
}

export function getLastNumInString(str: string) {
  const matchNum = str.match(/\s+\d+$/);
  return matchNum;
}

export function getDuplicateX(product: SelectedProduct): number {
  const threshold = randomIntFromInterval(20, 50);
  return product.width + threshold;
}

export function getDuplicateZ(product: SelectedProduct): number {
  const threshold = randomIntFromInterval(20, 50);
  return product.length + threshold;
}

export function getProductsWithAmountAndPrice(
  products: Array<Product> | null,
  project: Project | null
) {
  if (!products || !project || !project.products) {
    return null;
  }

  const productsWithAmountAndPrice = [];

  for (const product of products) {
    if (product.subcategory === "Holz" || product.subcategory === "Wood") {
      // Handle furniture wood seperately
      continue;
    }

    if (!product.price) {
      continue;
    }

    const selectedWithId = project.products.filter(
      (prod) => prod.pid === product.id
    );

    const weightAmount =
      getWeightAmount(product, project) * selectedWithId.length;
    const priceAmount =
      getPriceAmount(product, project) * selectedWithId.length;
    const totalPrice = priceAmount * product.price;
    productsWithAmountAndPrice.push({
      ...product,
      priceAmount,
      weightAmount,
      totalPrice,
    });
  }

  // Handle furniture wood seperately
  for (const product of products) {
    if (product.subcategory !== "Holz" && product.subcategory !== "Wood") {
      continue;
    }

    if (!product.price) {
      continue;
    }

    const selectedWithId = project.products.filter(
      (prod) => prod.pid === product.id
    );

    const priceAmount =
      getFurniturePriceAmount(product, project) * selectedWithId.length;
    const weightAmount =
      getFurnitureWeightAmount(product, project) * selectedWithId.length;
    const totalPrice = priceAmount * product.price;

    productsWithAmountAndPrice.push({
      ...product,
      priceAmount,
      weightAmount,
      totalPrice,
    });
  }

  return productsWithAmountAndPrice;
}

export function getWeightAmount(
  product: {
    id: number;
    width?: number | undefined | null;
    height?: number | undefined | null;
    topcategory: TopCategory;
  },
  project: Project
) {
  if (!product.width || !product.height) {
    return 1;
  } else if (product.topcategory === TopCategory.GROUND) {
    const groundSurface = project.width * project.length; // cm^2
    const productSurface = product.width * product.height; // cm^2
    return groundSurface / productSurface;
  } else if (product.topcategory === TopCategory.WALLS) {
    const wallSurface =
      2 * project.height * project.length + project.width * project.length; // cm^2
    const productSurface = product.width * product.height; // cm^2
    return wallSurface / productSurface;
  } else {
    return 1;
  }
}

export function getPriceAmount(
  product: {
    id: number;
    width?: number | undefined | null;
    height?: number | undefined | null;
    topcategory: TopCategory;
  },
  project: Project
) {
  if (!product.width || !product.height) {
    return 1;
  } else if (product.topcategory === TopCategory.GROUND) {
    const groundSurface = project.width * project.length; // cm^2
    const productSurface = product.width * product.height; // cm^2
    return Math.ceil(groundSurface / productSurface);
  } else if (product.topcategory === TopCategory.WALLS) {
    const wallSurface =
      2 * project.height * project.length + project.width * project.length; // cm^2
    const productSurface = product.width * product.height; // cm^2
    return Math.ceil(wallSurface / productSurface);
  } else {
    return 1;
  }
}

export function getFurnitureWeightAmount(
  product: {
    id: number;
    width?: number | undefined | null;
    height?: number | undefined | null;
    topcategory: TopCategory;
    subcategory: string;
  },
  project: Project
) {
  if (!product.width || !product.height || !project.products) {
    return 1;
  } else if (product.subcategory !== "Holz" && product.subcategory !== "Wood") {
    const selectedWithId = project.products.filter(
      (prod) => prod.pid === product.id
    );

    const avgLength =
      selectedWithId.reduce((a, b) => a + b.length, 0) / selectedWithId.length;
    const avgWidth =
      selectedWithId.reduce((a, b) => a + b.width, 0) / selectedWithId.length;
    const avgHeight =
      selectedWithId.reduce((a, b) => a + b.height, 0) / selectedWithId.length;
    const surface =
      1.5 * avgHeight * avgWidth +
      1.5 * avgHeight * avgLength +
      1.5 * avgLength * avgWidth; // cm^2
    const productSurface = product.width * product.height; // cm^2
    return surface / productSurface;
  } else {
    return 1;
  }
}

export function getFurniturePriceAmount(
  product: {
    id: number;
    width?: number | undefined | null;
    height?: number | undefined | null;
    topcategory: TopCategory;
    subcategory: string;
  },
  project: Project
) {
  if (!product.width || !product.height || !project.products) {
    return 1;
  } else if (product.subcategory !== "Holz" && product.subcategory !== "Wood") {
    const selectedWithId = project.products.filter(
      (prod) => prod.pid === product.id
    );

    const avgLength =
      selectedWithId.reduce((a, b) => a + b.length, 0) / selectedWithId.length;
    const avgWidth =
      selectedWithId.reduce((a, b) => a + b.width, 0) / selectedWithId.length;
    const avgHeight =
      selectedWithId.reduce((a, b) => a + b.height, 0) / selectedWithId.length;
    const surface =
      1.5 * avgHeight * avgWidth +
      1.5 * avgHeight * avgLength +
      1.5 * avgLength * avgWidth; // cm^2
    const productSurface = product.width * product.height; // cm^2
    return Math.ceil(surface / productSurface);
  } else {
    return 1;
  }
}

export function getAmplitudeUserId(id: number) {
  return String(id).padStart(5, "0");
}

export function encrypt(text: string, salt: string) {
  const b64 = CryptoJs.AES.encrypt(text, salt).toString();
  const e64 = CryptoJs.enc.Base64.parse(b64);
  const eHex = e64.toString(CryptoJs.enc.Hex);
  return eHex;
}

export function decrypt(text: string, salt: string) {
  const reb64 = CryptoJs.enc.Hex.parse(text);
  const bytes = reb64.toString(CryptoJs.enc.Base64);
  const decrypt = CryptoJs.AES.decrypt(bytes, salt);
  return decrypt.toString(CryptoJs.enc.Utf8);
}
