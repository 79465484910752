import { GetterTree, ActionTree, MutationTree } from "vuex";
import { CancellablePromiseLike } from "sequential-task-queue";
import jimp from "jimp";
import amplitude from "amplitude-js";
import { TopCategory } from "../types/category";
import { Project, SelectedProduct, Shape } from "../types/project";
import { Settings } from "../types/user";
import {
  getDuplicateX,
  getDuplicateZ,
  hashHexToNumber,
  getLanguage,
} from "../shared";
import { Product } from "../types/product";
import {
  HistoryAction,
  HistoryActionType,
  InteractionMode,
  ThreeWorld,
} from "../types/three";
import { handleError } from "../shared/errorHandler";
import { World } from "../three/World";

export const getDefaultState = () =>
  ({
    selectedObject: null,
    copiedObject: null,
    mode: InteractionMode.TRANSLATE,
    settings: null,
    history: [],
    redoHistory: [],
    shape: null,
    pid: null,
    subcategory: null,
    world: null,
    performanceMode: false,
    isDisablingPerformanceMode: false,
    exportProgress: 0,
    exportFilename: null,
    exportFormat: null,
  } as {
    selectedObject: {
      pid: number | null;
      uuid: string;
      ground?: boolean;
      walls?: boolean;
    } | null;
    copiedObject: {
      pid: number | null;
      uuid: string;
      ground?: boolean;
      walls?: boolean;
    } | null;
    mode: InteractionMode;
    history: HistoryAction[];
    redoHistory: HistoryAction[];
    shape: Shape | null;
    pid: number | null;
    subcategory: string | null;
    settings: Settings | null;
    world: ThreeWorld | null;
    performanceMode: boolean;
    isDisablingPerformanceMode: boolean;
    exportProgress: number;
    exportFilename: string | null;
    exportFormat: string | null;
  });

export const state = () => getDefaultState();

export type RootState = ReturnType<typeof state>;

export const getters: GetterTree<RootState, RootState> = {
  settings: (state) => {
    return state.settings ? { ...state.settings } : null;
  },

  copiedObject: (state) => {
    return state.copiedObject ? { ...state.copiedObject } : null;
  },

  selectedObject: (state) => {
    return state.selectedObject ? { ...state.selectedObject } : null;
  },

  exportProgress: (state) => {
    return state.exportProgress;
  },

  exportFilename: (state) => {
    return state.exportFilename;
  },

  exportFormat: (state) => {
    return state.exportFormat;
  },

  isObjectSelected: (state) => {
    return state.selectedObject != null;
  },

  mode: (state) => {
    return state.mode;
  },

  performanceMode: (state) => {
    return state.performanceMode;
  },

  isDisablingPerformanceMode: (state) => {
    return state.isDisablingPerformanceMode;
  },

  subcategory: (state) => {
    return state.subcategory;
  },

  world: (state) => {
    return state.world;
  },

  pid: (state) => {
    return state.pid;
  },

  history: (state) => {
    return state.history ? [...state.history] : [];
  },

  redoHistory: (state) => {
    return state.redoHistory ? [...state.redoHistory] : [];
  },

  shape: (state) => {
    return state.shape;
  },

  showVanHead: (state) => {
    return state.settings
      ? state.settings.showVanHead
      : process.env.NODE_ENV === "production";
  },

  showGrid: (state) => {
    return state.settings ? state.settings.showGrid : false;
  },

  showFront: (state) => {
    return state.settings ? state.settings.showFront : false;
  },

  showBack: (state) => {
    return state.settings ? state.settings.showBack : false;
  },

  showLeftWall: (state) => {
    return state.settings ? state.settings.showLeftWall : false;
  },

  showRightWall: (state) => {
    return state.settings ? state.settings.showRightWall : false;
  },

  showGround: (state) => {
    return state.settings ? state.settings.showGround : false;
  },

  showRoof: (state) => {
    return state.settings ? state.settings.showRoof : false;
  },

  shouldAutosave: (state) => {
    return state.settings ? state.settings.shouldAutosave : false;
  },

  autosaveInterval: (state) => {
    return state.settings ? state.settings.autosaveInterval : 30;
  },

  gridSize: (state) => {
    return state.settings ? state.settings.gridSize : 10;
  },

  snippingSensitivity: (state) => {
    return state.settings ? state.settings.snippingSensitivity : 5;
  },

  moveClipping: (state) => {
    return state.settings ? state.settings.moveClipping : 1;
  },

  rotationClipping: (state) => {
    return state.settings ? state.settings.rotationClipping : 10;
  },

  scaleClipping: (state) => {
    return state.settings ? state.settings.scaleClipping : 1;
  },
};

export const mutations: MutationTree<RootState> = {
  resetState(state) {
    Object.assign(state, getDefaultState());
  },

  setWorld(state, world: World) {
    state.world = world;
  },

  setSettings(state, settings: Settings | null) {
    state.settings = settings ? { ...settings } : null;
  },

  setExportProgress(state, progress: number) {
    state.exportProgress = progress;
  },

  setExportFilename(state, filename: string) {
    state.exportFilename = filename;
  },

  setExportFormat(state, format: string) {
    state.exportFormat = format;
  },

  setShape(state, shape: Shape | null) {
    state.shape = shape;
  },

  setPerformanceMode(state, enabled: boolean) {
    state.performanceMode = enabled;
  },

  togglePerformanceMode(state) {
    state.performanceMode = !state.performanceMode;
  },

  setIsDisablingPerformanceMode(state, enabled: boolean) {
    state.isDisablingPerformanceMode = enabled;
  },

  setVanHead(state, showHead: boolean) {
    if (state.settings) state.settings.showVanHead = showHead;
  },

  setSubcategory(state, subcategory: string | null) {
    state.subcategory = subcategory;
  },

  setPid(state, pid: number | null) {
    state.pid = pid;
  },

  resize(state) {
    if (state.world) state.world.onResize();
  },

  setSelectedObject(
    state,
    obj: {
      pid: number | null;
      uuid: string;
      ground?: boolean;
      walls?: boolean;
    } | null
  ) {
    state.selectedObject = obj ? { ...obj } : null;
    if (state.world && obj) {
      state.world.setSelectedObject(obj.uuid);
    }
  },

  setCopiedObject(state) {
    state.copiedObject = state.selectedObject
      ? { ...state.selectedObject }
      : null;
  },

  setMode(state, newMode: InteractionMode) {
    state.mode = newMode;
    state.world?.setMode(newMode);
  },

  addToHistory(state, action: any) {
    state.history.push(action);
    state.redoHistory = [];
  },

  toggleShouldAutosave(state) {
    if (!state.settings) return;
    state.settings.shouldAutosave = !state.settings.shouldAutosave;
  },

  changeAutosaveInterval(state, newInterval: number) {
    if (!state.settings) return;
    state.settings.autosaveInterval = newInterval;
  },
};

export const actions: ActionTree<RootState, RootState> = {
  togglePerformanceMode({ commit, getters, rootGetters }) {
    return new Promise((resolve) => {
      commit("togglePerformanceMode");
      const performanceMode: boolean = getters.performanceMode;
      const world: World = getters.world;

      if (performanceMode) {
        const queueRes = this.$queue.push(world.enablePerformanceMode, []);

        if (!queueRes) {
          return resolve(null);
        }

        queueRes.then(
          () => {
            resolve(null);
          },
          (err) => {
            handleError(
              err,
              getLanguage(this.$i18n.locale),
              undefined,
              {
                file: "store/three",
                method: "togglePerformanceMode",
              },
              true
            );
            resolve(this.$i18n.tc("store.three.toggle_performance_mode_error"));
          }
        );
      } else {
        commit("setIsDisablingPerformanceMode", true);
        const project = rootGetters["projects/activeProject"];
        const queueRes = this.$queue.push(world.disablePerformanceMode, [
          project,
          getters.settings,
        ]);

        if (!queueRes) {
          commit("setIsDisablingPerformanceMode", false);
          return resolve(null);
        }

        queueRes.then(
          () => {
            commit("setIsDisablingPerformanceMode", false);
            resolve(null);
          },
          (err) => {
            commit("setIsDisablingPerformanceMode", false);
            handleError(
              err,
              getLanguage(this.$i18n.locale),
              undefined,
              {
                file: "store/three",
                method: "togglePerformanceMode",
              },
              true
            );
            resolve(this.$i18n.tc("store.three.toggle_performance_mode_error"));
          }
        );
      }
    });
  },

  toggleGrid({ getters, rootGetters }) {
    try {
      const project = rootGetters["projects/activeProject"];
      const world: World = getters.world;

      if (!world || !project || !getters.settings) return;

      getters.settings.showGrid = !getters.settings.showGrid;

      world.toggleGrid({
        showGrid: getters.settings.showGrid,
        project,
        gridSize: getters.settings.gridSize,
        showFront: getters.settings.showFront,
        showBack: getters.settings.showBack,
        showLeftWall: getters.settings.showLeftWall,
        showRightWall: getters.settings.showRightWall,
        showGround: getters.settings.showGround,
        showRoof: getters.settings.showRoof,
      });
    } catch (err) {
      return handleError(
        err,
        getLanguage(this.$i18n.locale),
        undefined,
        {
          file: "store/three",
          method: "toggleGrid",
        },
        true
      );
    }
  },

  toggleFront({ getters, rootGetters }) {
    try {
      const project = rootGetters["projects/activeProject"];
      const world: World = getters.world;

      if (!world || !project || !getters.settings) return;

      getters.settings.showFront = !getters.settings.showFront;

      world.toggleFront({
        showGrid: getters.settings.showGrid,
        project,
        gridSize: getters.settings.gridSize,
        showFront: getters.settings.showFront,
        showBack: getters.settings.showBack,
        showLeftWall: getters.settings.showLeftWall,
        showRightWall: getters.settings.showRightWall,
        showGround: getters.settings.showGround,
        showRoof: getters.settings.showRoof,
      });
    } catch (err) {
      return handleError(
        err,
        getLanguage(this.$i18n.locale),
        undefined,
        {
          file: "store/three",
          method: "toggleFront",
        },
        true
      );
    }
  },

  toggleBack({ getters, rootGetters }) {
    try {
      const project = rootGetters["projects/activeProject"];
      const world: World = getters.world;

      if (!world || !project || !getters.settings) return;

      getters.settings.showBack = !getters.settings.showBack;

      getters.world.toggleBack({
        showGrid: getters.settings.showGrid,
        project,
        gridSize: getters.settings.gridSize,
        showFront: getters.settings.showFront,
        showBack: getters.settings.showBack,
        showLeftWall: getters.settings.showLeftWall,
        showRightWall: getters.settings.showRightWall,
        showGround: getters.settings.showGround,
        showRoof: getters.settings.showRoof,
      });
    } catch (err) {
      return handleError(
        err,
        getLanguage(this.$i18n.locale),
        undefined,
        {
          file: "store/three",
          method: "toggleBack",
        },
        true
      );
    }
  },

  toggleLeft({ getters, rootGetters }) {
    try {
      const project = rootGetters["projects/activeProject"];
      const world: World = getters.world;

      if (!world || !project || !getters.settings) return;

      getters.settings.showLeftWall = !getters.settings.showLeftWall;

      getters.world.toggleLeft({
        showGrid: getters.settings.showGrid,
        project,
        gridSize: getters.settings.gridSize,
        showFront: getters.settings.showFront,
        showBack: getters.settings.showBack,
        showLeftWall: getters.settings.showLeftWall,
        showRightWall: getters.settings.showRightWall,
        showGround: getters.settings.showGround,
        showRoof: getters.settings.showRoof,
      });
    } catch (err) {
      return handleError(
        err,
        getLanguage(this.$i18n.locale),
        undefined,
        {
          file: "store/three",
          method: "toggleLeft",
        },
        true
      );
    }
  },

  toggleRight({ getters, rootGetters }) {
    try {
      const project = rootGetters["projects/activeProject"];
      const world: World = getters.world;

      if (!world || !project || !getters.settings) return;

      getters.settings.showRightWall = !getters.settings.showRightWall;

      getters.world.toggleRight({
        showGrid: getters.settings.showGrid,
        project,
        gridSize: getters.settings.gridSize,
        showFront: getters.settings.showFront,
        showBack: getters.settings.showBack,
        showLeftWall: getters.settings.showLeftWall,
        showRightWall: getters.settings.showRightWall,
        showGround: getters.settings.showGround,
        showRoof: getters.settings.showRoof,
      });
    } catch (err) {
      return handleError(
        err,
        getLanguage(this.$i18n.locale),
        undefined,
        {
          file: "store/three",
          method: "toggleRight",
        },
        true
      );
    }
  },

  toggleGround({ getters, rootGetters }) {
    try {
      const project = rootGetters["projects/activeProject"];
      const world: World = getters.world;

      if (!world || !project || !getters.settings) return;

      getters.settings.showGround = !getters.settings.showGround;

      getters.world.toggleGround({
        showGrid: getters.settings.showGrid,
        project,
        gridSize: getters.settings.gridSize,
        showFront: getters.settings.showFront,
        showBack: getters.settings.showBack,
        showLeftWall: getters.settings.showLeftWall,
        showRightWall: getters.settings.showRightWall,
        showGround: getters.settings.showGround,
        showRoof: getters.settings.showRoof,
      });
    } catch (err) {
      return handleError(
        err,
        getLanguage(this.$i18n.locale),
        undefined,
        {
          file: "store/three",
          method: "toggleGround",
        },
        true
      );
    }
  },

  toggleRoof({ getters, rootGetters }) {
    try {
      const project = rootGetters["projects/activeProject"];
      const world: World = getters.world;

      if (!world || !project || !getters.settings) return;

      getters.settings.showRoof = !getters.settings.showRoof;

      getters.world.toggleRoof({
        showGrid: getters.settings.showGrid,
        project,
        gridSize: getters.settings.gridSize,
        showFront: getters.settings.showFront,
        showBack: getters.settings.showBack,
        showLeftWall: getters.settings.showLeftWall,
        showRightWall: getters.settings.showRightWall,
        showGround: getters.settings.showGround,
        showRoof: getters.settings.showRoof,
      });
    } catch (err) {
      return handleError(
        err,
        getLanguage(this.$i18n.locale),
        undefined,
        {
          file: "store/three",
          method: "toggleRoof",
        },
        true
      );
    }
  },

  changeGridSize({ getters, rootGetters }, newSize: number) {
    try {
      const project = rootGetters["projects/activeProject"];
      const world: World = getters.world;

      if (!world || !project || !getters.settings || newSize <= 0) return;

      getters.settings.gridSize = newSize;

      if (getters.settings.showGrid) {
        getters.world.changeGridSize({
          project,
          gridSize: getters.settings.gridSize,
          showFront: getters.settings.showFront,
          showBack: getters.settings.showBack,
          showLeftWall: getters.settings.showLeftWall,
          showRightWall: getters.settings.showRightWall,
          showGround: getters.settings.showGround,
          showRoof: getters.settings.showRoof,
        });
      }
    } catch (err) {
      return handleError(
        err,
        getLanguage(this.$i18n.locale),
        undefined,
        {
          file: "store/three",
          method: "changeGridSize",
        },
        true
      );
    }
  },

  changeSnippingSensitivity({ getters, rootGetters }, newSensitivity: number) {
    try {
      const project = rootGetters["projects/activeProject"];
      const world: World = getters.world;

      if (!world || !project || !getters.settings || newSensitivity <= 0)
        return;

      getters.settings.snippingSensitivity = newSensitivity;

      getters.world.changeSnippingSensitivity(
        getters.settings.snippingSensitivity
      );
    } catch (err) {
      return handleError(
        err,
        getLanguage(this.$i18n.locale),
        undefined,
        {
          file: "store/three",
          method: "changeSnippingSensitivity",
        },
        true
      );
    }
  },

  changeMoveClipping({ getters, rootGetters }, newClipping: number) {
    try {
      const project = rootGetters["projects/activeProject"];
      const world: World = getters.world;

      if (!world || !project || !getters.settings || newClipping <= 0) return;

      getters.settings.moveClipping = newClipping;

      getters.world.changeMoveClipping(getters.settings.moveClipping);
    } catch (err) {
      return handleError(
        err,
        getLanguage(this.$i18n.locale),
        undefined,
        {
          file: "store/three",
          method: "changeMoveClipping",
        },
        true
      );
    }
  },

  changeRotationClipping({ getters, rootGetters }, newClipping: number) {
    try {
      const project = rootGetters["projects/activeProject"];
      const world: World = getters.world;

      if (!world || !project || !getters.settings || newClipping <= 0) return;

      getters.settings.rotationClipping = newClipping;

      getters.world.changeRotationClipping(getters.settings.rotationClipping);
    } catch (err) {
      return handleError(
        err,
        getLanguage(this.$i18n.locale),
        undefined,
        {
          file: "store/three",
          method: "changeRotationClipping",
        },
        true
      );
    }
  },

  changeScaleClipping({ getters, rootGetters }, newClipping: number) {
    try {
      const project = rootGetters["projects/activeProject"];
      const world: World = getters.world;

      if (!world || !project || !getters.settings || newClipping <= 0) return;

      getters.settings.scaleClipping = newClipping;

      getters.world.changeScaleClipping(getters.settings.scaleClipping);
    } catch (err) {
      return handleError(
        err,
        getLanguage(this.$i18n.locale),
        undefined,
        {
          file: "store/three",
          method: "changeRotationClipping",
        },
        true
      );
    }
  },

  changeProductName({ getters, dispatch }, newName: string) {
    return new Promise((resolve) => {
      const product = getters.selectedObject;

      if (!product) return resolve(null);

      const queueRes = this.$queue.push(dispatch, [
        "projects/changeProductName",
        {
          newName,
          uuid: product.uuid,
        },
        { root: true },
      ]);

      if (!queueRes) return resolve(null);

      queueRes.then(
        (res) => {
          if (typeof res === "string") {
            return resolve(res);
          }
          resolve(null);
        },
        (err) => {
          const errorMessage = handleError(
            err,
            getLanguage(this.$i18n.locale),
            undefined,
            {
              file: "store/three",
              method: "changeProductName",
            },
            true
          );
          resolve(errorMessage);
        }
      );
    });
  },

  changeGroundColor(
    { getters, dispatch, commit },
    payload: {
      newColor: undefined | null | string | number;
      addToHistory: boolean;
    }
  ) {
    return new Promise((resolve) => {
      const newColorNr =
        payload.newColor != null
          ? typeof payload.newColor === "string"
            ? hashHexToNumber(payload.newColor)
            : payload.newColor
          : null;
      const world: World = getters.world;

      if (!world) return resolve(null);

      const queueRes = this.$queue.push(dispatch, [
        "projects/changeGroundColor",
        newColorNr,
        { root: true },
      ]);

      if (!queueRes) return resolve(null);

      queueRes.then(
        (res) => {
          if (!res) return resolve(null);

          if (typeof res === "string") {
            return resolve(res);
          }

          const { project, oldColor } = res;

          if (!project) return resolve(null);

          world?.setGroundColor(project.ground_color);

          const addToHistory =
            typeof payload.addToHistory === "boolean"
              ? payload.addToHistory
              : true;

          if (addToHistory) {
            commit("addToHistory", {
              type: HistoryActionType.COLOR_CHANGE,
              pid: null,
              uuid: "",
              payload: {
                product: null,
                ground: true,
                walls: false,
                oldColor,
                newColor: newColorNr,
              },
            });
          }

          resolve(null);
        },
        (err) => {
          const errorMessage = handleError(
            err,
            getLanguage(this.$i18n.locale),
            undefined,
            {
              file: "store/three",
              method: "changeGroundColor",
            },
            true
          );
          resolve(errorMessage);
        }
      );
    });
  },

  changeWallsColor(
    { getters, dispatch, commit },
    payload: {
      newColor: undefined | null | string | number;
      addToHistory: boolean;
    }
  ) {
    return new Promise((resolve) => {
      const newColorNr =
        payload.newColor != null
          ? typeof payload.newColor === "string"
            ? hashHexToNumber(payload.newColor)
            : payload.newColor
          : null;
      const world: World = getters.world;

      if (!world) return resolve(null);

      const queueRes = this.$queue.push(dispatch, [
        "projects/changeWallsColor",
        newColorNr,
        { root: true },
      ]);

      if (!queueRes) return resolve(null);

      queueRes.then(
        (res) => {
          if (!res) return resolve(null);

          if (typeof res === "string") {
            return resolve(res);
          }

          const { project, oldColor } = res;

          if (!project) return resolve(null);

          world?.setWallsColor(project, project.walls_color);

          const addToHistory =
            typeof payload.addToHistory === "boolean"
              ? payload.addToHistory
              : true;

          if (addToHistory) {
            commit("addToHistory", {
              type: HistoryActionType.COLOR_CHANGE,
              pid: null,
              uuid: "",
              payload: {
                product: null,
                ground: false,
                walls: true,
                oldColor,
                newColor: newColorNr,
              },
            });
          }

          resolve(null);
        },
        (err) => {
          const errorMessage = handleError(
            err,
            getLanguage(this.$i18n.locale),
            undefined,
            {
              file: "store/three",
              method: "changeWallsColor",
            },
            true
          );
          resolve(errorMessage);
        }
      );
    });
  },

  changeProductAppearance(
    { getters, dispatch, commit },
    payload: {
      newPid: null | number;
      newColor: undefined | null | string | number;
      addToHistory: boolean;
    }
  ) {
    return new Promise((resolve) => {
      const newColorNr =
        payload.newColor != null
          ? typeof payload.newColor === "string"
            ? hashHexToNumber(payload.newColor)
            : payload.newColor
          : null;
      const product = getters.selectedObject;
      const world: World = getters.world;

      if (!product || !world) return resolve(null);

      const queueRes = this.$queue.push(dispatch, [
        "projects/changeProductAppearance",
        { newPid: payload.newPid, newColor: newColorNr, uuid: product.uuid },
        { root: true },
      ]);

      if (!queueRes) return resolve(null);

      queueRes.then(
        (res) => {
          if (!res) return resolve(null);

          if (typeof res === "string") {
            return resolve(res);
          }

          const {
            project,
            oldProduct,
            newProduct,
            pid,
            uuid,
            oldColor,
            newColor,
          } = res;

          if (!project) return resolve(null);

          world?.changeProductAppearance(product.uuid, newProduct);

          const addToHistory =
            typeof payload.addToHistory === "boolean"
              ? payload.addToHistory
              : true;

          if (addToHistory) {
            commit("addToHistory", {
              type: HistoryActionType.PRODUCT_APPEARANCE_CHANGE,
              pid,
              uuid,
              payload: {
                oldProduct,
                newProduct,
                oldColor,
                newColor,
                ground: false,
                walls: false,
              },
            });
          }

          resolve(null);
        },
        (err) => {
          const errorMessage = handleError(
            err,
            getLanguage(this.$i18n.locale),
            undefined,
            {
              file: "store/three",
              method: "changeProductAppearance",
            },
            true
          );
          resolve(errorMessage);
        }
      );
    });
  },

  hideProduct(
    { getters, dispatch, commit },
    payload: {
      product: {
        pid: number;
        uuid: string;
        ground: boolean;
        walls: boolean;
      } | null;
      addToHistory: boolean;
    }
  ) {
    return new Promise((resolve) => {
      const selectedObject = getters.selectedObject;
      const world: World = getters.world;

      const product: {
        pid: number | null;
        uuid: string;
        ground?: boolean;
        walls?: boolean;
      } | null = payload.product ?? selectedObject;

      if (!world || !product) return resolve(null);

      const queueRes = this.$queue.push(dispatch, [
        "projects/hideProduct",
        product,
        { root: true },
      ]);

      if (!queueRes) return resolve(null);

      queueRes.then(
        (res) => {
          if (!res) return resolve(null);

          if (typeof res === "string") {
            return resolve(res);
          }

          const { oldColor, hideProduct, project } = res;

          if (!project) return resolve(null);

          if (product.walls) {
            world?.removeWallsTexture(project, oldColor);
          } else if (product.ground) {
            world?.removeGroundTexture(oldColor);
          } else {
            world?.deleteProduct(product.uuid);
          }

          const addToHistory =
            typeof payload.addToHistory === "boolean"
              ? payload.addToHistory
              : true;

          if (addToHistory) {
            commit("addToHistory", {
              type: HistoryActionType.HIDE,
              pid: product.pid,
              uuid: product.uuid,
              payload: {
                product: hideProduct,
                ground: product.ground,
                walls: product.walls,
                oldColor,
                newColor: oldColor,
              },
            });
          }

          resolve(null);
        },
        (err) => {
          const errorMessage = handleError(
            err,
            getLanguage(this.$i18n.locale),
            undefined,
            {
              file: "store/three",
              method: "hideProduct",
            },
            true
          );
          resolve(errorMessage);
        }
      );
    });
  },

  showProduct(
    { getters, dispatch, commit },
    payload: {
      product: {
        pid: number;
        uuid: string;
        ground: boolean;
        walls: boolean;
        select: boolean;
      } | null;
      addToHistory: boolean;
    }
  ) {
    return new Promise((resolve) => {
      const selectedObject = getters.selectedObject;
      const world: World = getters.world;

      const product: {
        pid: number | null;
        uuid: string;
        ground?: boolean;
        walls?: boolean;
        select?: boolean;
      } | null = payload.product ?? selectedObject;

      if (!world || !product) return resolve(null);

      const queueRes = this.$queue.push(dispatch, [
        "projects/showProduct",
        product,
        { root: true },
      ]);

      if (!queueRes) return resolve(null);

      queueRes.then(
        (res) => {
          if (!res) return resolve(null);

          if (typeof res === "string") {
            return resolve(res);
          }

          const { oldColor, showProduct, project } = res;

          if (!project) return resolve(null);

          if (product.walls) {
            world?.setWallsTexture(product.pid, project, product.uuid);
          } else if (product.ground) {
            world?.setGroundTexture(product.pid, product.uuid);
          } else {
            world?.addProduct(showProduct, product.select ?? false);
          }

          const addToHistory =
            typeof payload.addToHistory === "boolean"
              ? payload.addToHistory
              : true;

          if (addToHistory) {
            commit("addToHistory", {
              type: HistoryActionType.SHOW,
              pid: product.pid,
              uuid: product.uuid,
              payload: {
                product: showProduct,
                ground: product.ground,
                walls: product.walls,
                oldColor,
                newColor: oldColor,
              },
            });
          }

          resolve(null);
        },
        (err) => {
          const errorMessage = handleError(
            err,
            getLanguage(this.$i18n.locale),
            undefined,
            {
              file: "store/three",
              method: "showProduct",
            },
            true
          );
          resolve(errorMessage);
        }
      );
    });
  },

  deleteProduct(
    { getters, dispatch, commit },
    payload: {
      product: {
        pid: number;
        uuid: string;
        ground: boolean;
        walls: boolean;
      } | null;
      addToHistory: boolean;
    }
  ) {
    return new Promise((resolve) => {
      const selectedObject = getters.selectedObject;
      const world: World = getters.world;

      const product: {
        pid: number | null;
        uuid: string;
        ground?: boolean;
        walls?: boolean;
      } | null = payload.product ?? selectedObject;

      if (!world || !product) return resolve(null);

      const queueRes = this.$queue.push(dispatch, [
        "projects/deleteProduct",
        product,
        { root: true },
      ]);

      if (!queueRes) return resolve(null);

      queueRes.then(
        (res) => {
          if (!res) return resolve(null);

          if (typeof res === "string") {
            return resolve(res);
          }

          const { oldColor, delProduct, project } = res;

          if (!project) return resolve(null);

          if (product.walls) {
            world?.removeWallsTexture(project, oldColor);
          } else if (product.ground) {
            world?.removeGroundTexture(oldColor);
          } else {
            world?.deleteProduct(product.uuid);
          }

          const addToHistory =
            typeof payload.addToHistory === "boolean"
              ? payload.addToHistory
              : true;

          if (addToHistory) {
            commit("addToHistory", {
              type: HistoryActionType.DELETE,
              pid: product.pid,
              uuid: product.uuid,
              payload: {
                product: delProduct,
                ground: product.ground,
                walls: product.walls,
                oldColor,
                newColor: oldColor,
              },
            });
          }

          resolve(null);
        },
        (err) => {
          const errorMessage = handleError(
            err,
            getLanguage(this.$i18n.locale),
            undefined,
            {
              file: "store/three",
              method: "deleteProduct",
            },
            true
          );
          resolve(errorMessage);
        }
      );
    });
  },

  addProduct(
    { getters, dispatch, commit },
    payload: {
      product: {
        pid: number;
        uuid?: string | undefined | null;
        width: number;
        height: number;
        length: number;
        ground: boolean;
        walls: boolean;
        color?: undefined | null | string;
        shape?: undefined | null | Shape;
        name: string;
        visible: boolean;
        x?: number | undefined; // For duplication
        z?: number | undefined; // For duplication
        sX?: number | undefined; // For duplication
        sY?: number | undefined; // For duplication
        sZ?: number | undefined; // For duplication
        select?: boolean;
      };
      addToHistory: boolean;
    }
  ) {
    return new Promise((resolve) => {
      const world: World = getters.world;

      if (!world) return resolve(null);

      let queueRes!: CancellablePromiseLike<any> | null;

      if (payload.product.walls) {
        queueRes = this.$queue.push(dispatch, [
          "projects/addTexture",
          payload.product,
          { root: true },
        ]);
      } else if (payload.product.ground) {
        queueRes = this.$queue.push(dispatch, [
          "projects/addTexture",
          payload.product,
          { root: true },
        ]);
      } else {
        queueRes = this.$queue.push(dispatch, [
          "projects/addProduct",
          payload.product,
          { root: true },
        ]);
      }

      if (!queueRes) return resolve(null);

      queueRes.then(
        (res) => {
          if (!res) return resolve(null);

          if (typeof res === "string") {
            return resolve(res);
          }

          const { oldColor, addProduct, project } = res;

          if (!project) return resolve(null);

          if (payload.product.walls) {
            world?.setWallsTexture(
              payload.product.pid,
              project,
              addProduct.uuid
            );
          } else if (payload.product.ground) {
            world?.setGroundTexture(payload.product.pid, addProduct.uuid);
          } else {
            world?.addProduct(addProduct, payload.product.select ?? false);
          }

          const addToHistory =
            typeof payload.addToHistory === "boolean"
              ? payload.addToHistory
              : true;

          if (addToHistory) {
            commit("addToHistory", {
              type: HistoryActionType.ADD,
              pid: payload.product.pid,
              uuid: addProduct.uuid,
              payload: {
                product: addProduct,
                ground: payload.product.ground,
                walls: payload.product.walls,
                oldColor,
                newColor: oldColor,
              },
            });
          }

          resolve(null);
        },
        (err) => {
          const errorMessage = handleError(
            err,
            getLanguage(this.$i18n.locale),
            undefined,
            {
              file: "store/three",
              method: "addProduct",
            },
            true
          );
          resolve(errorMessage);
        }
      );
    });
  },

  moveProduct(
    { getters, dispatch, commit },
    payload: {
      product: {
        pid: number | null;
        uuid: string;
        newX: number;
        newY: number;
        newZ: number;
        moveInWorld?: boolean;
      };
      addToHistory: boolean;
    }
  ) {
    return new Promise((resolve) => {
      const world: World = getters.world;

      if (!world || !payload.product) return resolve(null);

      const queueRes = this.$queue.push(dispatch, [
        "projects/moveProduct",
        payload.product,
        { root: true },
      ]);

      if (!queueRes) return resolve(null);

      queueRes.then(
        (res) => {
          if (!res) return resolve(null);

          if (typeof res === "string") {
            return resolve(res);
          }

          const { project, deltaX, deltaY, deltaZ, oldProduct, newProduct } =
            res;

          if (!project) return resolve(null);

          const addToHistory =
            typeof payload.addToHistory === "boolean"
              ? payload.addToHistory
              : true;

          if (addToHistory && (deltaX !== 0 || deltaY !== 0 || deltaZ !== 0)) {
            commit("addToHistory", {
              type: HistoryActionType.MOVE,
              pid: payload.product.pid,
              uuid: payload.product.uuid,
              payload: {
                oldProduct,
                newProduct,
              },
            });
          }

          if (payload.product.moveInWorld) {
            world?.moveProduct({
              uuid: payload.product.uuid,
              deltaX,
              deltaY,
              deltaZ,
            });
          }

          resolve(null);
        },
        (err) => {
          const errorMessage = handleError(
            err,
            getLanguage(this.$i18n.locale),
            undefined,
            {
              file: "store/three",
              method: "moveProduct",
            },
            true
          );
          resolve(errorMessage);
        }
      );
    });
  },

  rotateProduct(
    { getters, dispatch, commit },
    payload: {
      product: {
        pid: number | null;
        uuid: string;
        newRx: number;
        newRy: number;
        newRz: number;
        rotateInWorld?: boolean;
      };
      addToHistory: boolean;
    }
  ) {
    return new Promise((resolve) => {
      const world: World = getters.world;

      if (!world || !payload.product) return resolve(null);

      const queueRes = this.$queue.push(dispatch, [
        "projects/rotateProduct",
        payload.product,
        { root: true },
      ]);

      if (!queueRes) return resolve(null);

      queueRes.then(
        (res) => {
          if (!res) return resolve(null);

          if (typeof res === "string") {
            return resolve(res);
          }

          const { project, deltaRx, deltaRy, deltaRz, oldProduct, newProduct } =
            res;

          if (!project) return resolve(null);

          const addToHistory =
            typeof payload.addToHistory === "boolean"
              ? payload.addToHistory
              : true;

          if (
            addToHistory &&
            (deltaRx !== 0 || deltaRy !== 0 || deltaRz !== 0)
          ) {
            commit("addToHistory", {
              type: HistoryActionType.ROTATE,
              pid: payload.product.pid,
              uuid: payload.product.uuid,
              payload: {
                oldProduct,
                newProduct,
              },
            });
          }

          if (payload.product.rotateInWorld) {
            world?.rotateProduct({
              uuid: payload.product.uuid,
              deltaRx,
              deltaRy,
              deltaRz,
            });
          }

          resolve(null);
        },
        (err) => {
          const errorMessage = handleError(
            err,
            getLanguage(this.$i18n.locale),
            undefined,
            {
              file: "store/three",
              method: "rotateProduct",
            },
            true
          );
          resolve(errorMessage);
        }
      );
    });
  },

  scaleProduct(
    { getters, dispatch, commit },
    payload: {
      product: {
        pid: number | null;
        uuid: string;
        newSx: number;
        newSy: number;
        newSz: number;
        scaleInWorld?: boolean;
      };
      addToHistory: boolean;
    }
  ) {
    return new Promise((resolve) => {
      const world: World = getters.world;

      if (!world || !payload.product) return resolve(null);

      const queueRes = this.$queue.push(dispatch, [
        "projects/scaleProduct",
        payload.product,
        { root: true },
      ]);

      if (!queueRes) return resolve(null);

      queueRes.then(
        (res) => {
          if (!res) return resolve(null);

          if (typeof res === "string") {
            return resolve(res);
          }

          const { project, deltaSx, deltaSy, deltaSz, oldProduct, newProduct } =
            res;

          if (!project) return resolve(null);

          const addToHistory =
            typeof payload.addToHistory === "boolean"
              ? payload.addToHistory
              : true;

          if (
            addToHistory &&
            (deltaSx !== 0 || deltaSy !== 0 || deltaSz !== 0)
          ) {
            commit("addToHistory", {
              type: HistoryActionType.SCALE,
              pid: payload.product.pid,
              uuid: payload.product.uuid,
              payload: {
                oldProduct,
                newProduct,
              },
            });
          }

          if (payload.product.scaleInWorld) {
            world?.scaleProduct({
              uuid: payload.product.uuid,
              deltaSx,
              deltaSy,
              deltaSz,
            });
          }

          resolve(null);
        },
        (err) => {
          const errorMessage = handleError(
            err,
            getLanguage(this.$i18n.locale),
            undefined,
            {
              file: "store/three",
              method: "scaleProduct",
            },
            true
          );
          resolve(errorMessage);
        }
      );
    });
  },

  async undo({ getters, dispatch }) {
    try {
      const world: World = getters.world;

      if (!world || getters.history.length <= 0) {
        return;
      }

      // 1. Remove current operation from history
      const undoCommand = getters.history[getters.history.length - 1];
      getters.history.pop();
      getters.redoHistory.push({ ...undoCommand });

      // 2. Perform undo
      let errorMessage: string | undefined | null = null;

      switch (undoCommand.type) {
        case HistoryActionType.DELETE:
          errorMessage = await dispatch("addProduct", {
            product: {
              ...undoCommand.payload.product,
              walls: undoCommand.payload.walls,
              ground: undoCommand.payload.ground,
            },
            addToHistory: false,
          });
          break;

        case HistoryActionType.HIDE:
          errorMessage = await dispatch("showProduct", {
            product: {
              ...undoCommand.payload.product,
              walls: undoCommand.payload.walls,
              ground: undoCommand.payload.ground,
            },
            addToHistory: false,
          });
          break;

        case HistoryActionType.ADD:
          errorMessage = await dispatch("deleteProduct", {
            product: {
              pid: undoCommand.pid,
              uuid: undoCommand.uuid,
              walls: undoCommand.payload.walls,
              ground: undoCommand.payload.ground,
            },
            addToHistory: false,
          });
          break;

        case HistoryActionType.SHOW:
          errorMessage = await dispatch("hideProduct", {
            product: {
              pid: undoCommand.pid,
              uuid: undoCommand.uuid,
              walls: undoCommand.payload.walls,
              ground: undoCommand.payload.ground,
            },
            addToHistory: false,
          });
          break;

        case HistoryActionType.MOVE:
          errorMessage = await dispatch("moveProduct", {
            product: {
              pid: undoCommand.pid,
              uuid: undoCommand.uuid,
              newX: undoCommand.payload.oldProduct.x,
              newY: undoCommand.payload.oldProduct.y,
              newZ: undoCommand.payload.oldProduct.z,
              moveInWorld: true,
            },
            addToHistory: false,
          });
          break;

        case HistoryActionType.ROTATE:
          errorMessage = await dispatch("rotateProduct", {
            product: {
              pid: undoCommand.pid,
              uuid: undoCommand.uuid,
              newRx: undoCommand.payload.oldProduct.rX,
              newRy: undoCommand.payload.oldProduct.rY,
              newRz: undoCommand.payload.oldProduct.rZ,
              rotateInWorld: true,
            },
            addToHistory: false,
          });
          break;

        case HistoryActionType.SCALE:
          errorMessage = await dispatch("scaleProduct", {
            product: {
              pid: undoCommand.pid,
              uuid: undoCommand.uuid,
              newSx: undoCommand.payload.oldProduct.sX,
              newSy: undoCommand.payload.oldProduct.sY,
              newSz: undoCommand.payload.oldProduct.sZ,
              scaleInWorld: true,
            },
            addToHistory: false,
          });
          break;

        case HistoryActionType.COLOR_CHANGE:
          // Perform correct undo operation in scene
          if (undoCommand.payload.ground) {
            errorMessage = await dispatch("changeGroundColor", {
              newColor: undoCommand.payload.oldColor,
              addToHistory: false,
            });
          } else if (undoCommand.payload.walls) {
            errorMessage = await dispatch("changeWallsColor", {
              newColor: undoCommand.payload.oldColor,
              addToHistory: false,
            });
          }
          break;

        case HistoryActionType.PRODUCT_APPEARANCE_CHANGE:
          errorMessage = await dispatch("changeProductAppearance", {
            newPid: undoCommand.payload.oldProduct.pid,
            newColor: undoCommand.payload.oldColor,
            addToHistory: false,
          });
          break;
      }

      if (errorMessage) {
        return errorMessage;
      }
    } catch (err) {
      return handleError(
        err,
        getLanguage(this.$i18n.locale),
        undefined,
        {
          file: "store/three",
          method: "undo",
        },
        true
      );
    }
  },

  async redo({ getters, dispatch }) {
    try {
      const world: World = getters.world;

      if (!world || getters.redoHistory.length <= 0) {
        return;
      }

      // 1. Remove current operation from history
      const redoCommand = getters.redoHistory[getters.redoHistory.length - 1];
      getters.redoHistory.pop();
      getters.history.push({ ...redoCommand });

      // 2. Perform redo
      let errorMessage: string | undefined | null = null;

      switch (redoCommand.type) {
        case HistoryActionType.DELETE:
          errorMessage = await dispatch("deleteProduct", {
            product: {
              pid: redoCommand.pid,
              uuid: redoCommand.uuid,
              walls: redoCommand.payload.walls,
              ground: redoCommand.payload.ground,
            },
            addToHistory: false,
          });
          break;

        case HistoryActionType.ADD:
          errorMessage = await dispatch("addProduct", {
            product: {
              ...redoCommand.payload.product,
              walls: redoCommand.payload.walls,
              ground: redoCommand.payload.ground,
            },
            addToHistory: false,
          });
          break;

        case HistoryActionType.HIDE:
          errorMessage = await dispatch("hideProduct", {
            product: {
              pid: redoCommand.pid,
              uuid: redoCommand.uuid,
              walls: redoCommand.payload.walls,
              ground: redoCommand.payload.ground,
            },
            addToHistory: false,
          });
          break;

        case HistoryActionType.SHOW:
          errorMessage = await dispatch("showProduct", {
            product: {
              ...redoCommand.payload.product,
              walls: redoCommand.payload.walls,
              ground: redoCommand.payload.ground,
              addToHistory: false,
            },
            addToHistory: false,
          });
          break;

        case HistoryActionType.MOVE:
          errorMessage = await dispatch("moveProduct", {
            product: {
              pid: redoCommand.pid,
              uuid: redoCommand.uuid,
              newX: redoCommand.payload.newProduct.x,
              newY: redoCommand.payload.newProduct.y,
              newZ: redoCommand.payload.newProduct.z,
              moveInWorld: true,
            },
            addToHistory: false,
          });
          break;

        case HistoryActionType.ROTATE:
          errorMessage = await dispatch("rotateProduct", {
            product: {
              pid: redoCommand.pid,
              uuid: redoCommand.uuid,
              newRx: redoCommand.payload.newProduct.rX,
              newRy: redoCommand.payload.newProduct.rY,
              newRz: redoCommand.payload.newProduct.rZ,
              rotateInWorld: true,
            },
            addToHistory: false,
          });
          break;

        case HistoryActionType.SCALE:
          errorMessage = await dispatch("scaleProduct", {
            product: {
              pid: redoCommand.pid,
              uuid: redoCommand.uuid,
              newSx: redoCommand.payload.newProduct.sX,
              newSy: redoCommand.payload.newProduct.sY,
              newSz: redoCommand.payload.newProduct.sZ,
              scaleInWorld: true,
            },
            addToHistory: false,
          });
          break;

        case HistoryActionType.COLOR_CHANGE:
          // Perform correct undo operation in scene
          if (redoCommand.payload.ground) {
            errorMessage = await dispatch("changeGroundColor", {
              newColor: redoCommand.payload.newColor,
              addToHistory: false,
            });
          } else if (redoCommand.payload.walls) {
            errorMessage = await dispatch("changeWallsColor", {
              newColor: redoCommand.payload.newColor,
              addToHistory: false,
            });
          }
          break;

        case HistoryActionType.PRODUCT_APPEARANCE_CHANGE:
          errorMessage = await dispatch("changeProductAppearance", {
            newPid: redoCommand.payload.newProduct.pid,
            newColor: redoCommand.payload.newColor,
            addToHistory: false,
          });
          break;
      }

      if (errorMessage) {
        return errorMessage;
      }
    } catch (err) {
      return handleError(
        err,
        getLanguage(this.$i18n.locale),
        undefined,
        {
          file: "store/three",
          method: "redo",
        },
        true
      );
    }
  },

  async updateSettings({ getters, dispatch }) {
    try {
      if (getters.settings) {
        return await dispatch("updateSettings", getters.settings, {
          root: true,
        });
      }
    } catch (err) {
      return handleError(
        err,
        getLanguage(this.$i18n.locale),
        undefined,
        {
          file: "store/three",
          method: "updateSettings",
        },
        true
      );
    }
  },

  toggleVanHead({ getters, commit, rootGetters }) {
    return new Promise((resolve) => {
      const project = rootGetters["projects/activeProject"];
      const world: World = getters.world;

      if (!getters.settings || !project || !world) return;

      commit("setVanHead", !getters.settings.showVanHead);

      const queueRes = this.$queue.push(world.toggleVanHead, [
        getters.settings.showVanHead,
        project,
      ]);

      if (!queueRes) return resolve(null);

      queueRes.then(
        () => {
          resolve(null);
        },
        (err) => {
          const errorMessage = handleError(
            err,
            getLanguage(this.$i18n.locale),
            undefined,
            {
              file: "store/three",
              method: "toggleVanHead",
            },
            true
          );
          resolve(errorMessage);
        }
      );
    });
  },

  async pasteCopiedObject({ dispatch, rootGetters }) {
    const product: SelectedProduct | string | undefined | null = await dispatch(
      "copiedObjectDetails"
    );

    if (typeof product === "string") {
      return product;
    }

    const project = rootGetters["projects/activeProject"];

    if (!product || !project) return;

    const productDetails: Product | null = rootGetters[
      "products/productDetails"
    ](product.pid);

    if (
      productDetails &&
      (productDetails.topcategory === TopCategory.GROUND ||
        productDetails.topcategory === TopCategory.WALLS)
    ) {
      return;
    }

    const errorMessage = await dispatch("addProduct", {
      product: {
        pid: product.pid,
        width: product.width,
        height: product.height,
        length: product.length,
        ground: false,
        color: product.color,
        shape: product.shape,
        walls: false,
        name: product.name,
        visible: true,
        x: getDuplicateX(product),
        z: getDuplicateZ(product),
        sX: product.sX,
        sY: product.sY,
        sZ: product.sZ,
      },
      addToHistory: true,
    });

    if (errorMessage) {
      return errorMessage;
    }
  },

  async duplicateObject(
    { dispatch, rootGetters },
    toDuplicate: SelectedProduct | undefined | null
  ) {
    try {
      const product: SelectedProduct | string | undefined | null =
        toDuplicate ?? (await dispatch("selectedObjectDetails"));

      if (typeof product === "string") {
        return product;
      }

      if (!product) return;

      if (product.shape != null) {
        const textureDetails: Product | null = rootGetters[
          "products/textureDetails"
        ](product.pid);

        if (
          textureDetails &&
          (textureDetails.topcategory === TopCategory.GROUND ||
            textureDetails.topcategory === TopCategory.WALLS)
        ) {
          return;
        }
      }

      const errorMessage = await dispatch("addProduct", {
        product: {
          pid: product.pid,
          width: product.width,
          height: product.height,
          length: product.length,
          ground: false,
          color: product.color,
          shape: product.shape,
          walls: false,
          name: product.name,
          visible: true,
          x: getDuplicateX(product),
          z: getDuplicateZ(product),
          sX: product.sX,
          sY: product.sY,
          sZ: product.sZ,
          select: true,
        },
        addToHistory: true,
      });

      if (errorMessage) {
        return errorMessage;
      }
    } catch (err) {
      return handleError(
        err,
        getLanguage(this.$i18n.locale),
        undefined,
        {
          file: "store/three",
          method: "duplicateObject",
        },
        true
      );
    }
  },

  selectedObjectDetails({ getters, rootGetters }) {
    const selectedObject = getters.selectedObject;

    if (!selectedObject) return null;

    const project: Project = rootGetters["projects/activeProject"];

    if (!project || !project.products) return null;

    const productDetails = project.products.find(
      (iProduct) => iProduct.uuid === selectedObject.uuid
    );

    return productDetails ? { ...productDetails } : null;
  },

  copiedObjectDetails({ getters, rootGetters }) {
    const copiedObjectObject = getters.copiedObject;

    if (!copiedObjectObject) return null;

    const project: Project = rootGetters["projects/activeProject"];

    if (!project || !project.products) return null;

    const productDetails = project.products.find(
      (iProduct) => iProduct.uuid === copiedObjectObject.uuid
    );

    return productDetails ? { ...productDetails } : null;
  },

  exportImage(
    { getters, commit },
    payload: { format: string; filename: string }
  ) {
    return new Promise((resolve) => {
      const world: World = getters.world;

      if (!world) return;

      const onError = (err: any) => {
        const errorMessage = handleError(
          err,
          getLanguage(this.$i18n.locale),
          undefined,
          {
            file: "store/three",
            method: "exportImage",
          },
          true
        );
        resolve(errorMessage);
      };

      const onSuccess = (imgData: string) => {
        const queueRes = this.$queue.push(
          imageOverlay,
          [imgData, payload.format],
          10000
        );

        if (!queueRes) return resolve(null);

        commit("setExportProgress", 0.5);

        queueRes.then((imgData: string) => {
          if (document) {
            const link = document.createElement("a");
            link.download = payload.filename;
            link.href = imgData;
            link.click();
            link.remove();
          }
          commit("setExportProgress", 1);

          if (process.client) {
            amplitude.getInstance().logEvent("export_project", {
              format: payload.format,
              duration: 0,
            });
          }

          resolve(null);
        }, onError);
      };

      const queueRes = this.$queue.push(
        world.exportImage,
        [payload.format],
        10000
      );

      if (!queueRes) return resolve(null);

      commit("setExportFilename", payload.filename);
      commit("setExportFormat", payload.format);
      commit("setExportProgress", 0);

      if (window) {
        window.location.hash = "export-progress";
      }

      queueRes.then(onSuccess, onError);
    });
  },

  exportVideo(
    { getters, commit },
    payload: { filename: string; duration: number; format: string }
  ) {
    return new Promise((resolve) => {
      const world: World = getters.world;

      if (!world) return;

      const onError = (err: any) => {
        const errorMessage = handleError(
          err,
          getLanguage(this.$i18n.locale),
          undefined,
          {
            file: "store/three",
            method: "exportVideo",
          },
          true
        );
        resolve(errorMessage);
      };

      const queueRes = this.$queue.push(
        world.startExportVideo,
        [payload.duration],
        300000
      );

      if (!queueRes) return resolve(null);

      commit("setExportFilename", payload.filename);
      commit("setExportFormat", payload.format);
      commit("setExportProgress", 0);

      let curProgress = 0;
      const tick = 1000 / payload.duration;

      const progressTracker = setInterval(() => {
        curProgress += tick;
        commit("setExportProgress", curProgress);
      }, 1000);

      queueRes.then((videoData) => {
        clearInterval(progressTracker);

        if (document) {
          const link = document.createElement("a");
          link.download = payload.filename;
          link.href = URL.createObjectURL(videoData);
          link.click();
          link.remove();
        }

        commit("setExportProgress", 1);

        if (process.client) {
          amplitude.getInstance().logEvent("export_project", {
            format: payload.format,
            duration: payload.duration,
          });
        }

        resolve(null);
      }, onError);
    });
  },

  stopVideoExport({ getters }) {
    const world: World = getters.world;

    if (!world) return;

    world.stopExportVideo();
  },
};

async function imageOverlay(imgData: string, format: string) {
  // Reading watermark Image
  let watermark = await jimp.read(require("~/assets/img/logo/watermark.png"));

  const image = await jimp.read(
    Buffer.from(
      imgData
        .replace(/^data:image\/png;base64,/, "")
        .replace(/^data:image\/jpeg;base64,/, ""),
      "base64"
    )
  );

  image.crop(60, 50, image.bitmap.width - 60, image.bitmap.height - 50);

  watermark = watermark.resize(75, 75);

  image.composite(
    watermark,
    image.bitmap.width - 100,
    image.bitmap.height - 100,
    {
      mode: jimp.BLEND_SOURCE_OVER,
      opacityDest: 1,
      opacitySource: 1,
    }
  );

  return await image.getBase64Async(format);
}
