import { GetterTree, ActionTree, MutationTree } from "vuex";
import { TopCategory } from "../types/category";
import { SelectedProduct } from "../types/project";
import { Product, Texture, Van } from "../types/product";
import products from "../config/products.json";
import vans from "../config/vans.json";
import textures from "../config/textures.json";
import { groupBy } from "~/shared";
import { defaultVanImage } from "~/shared/constants";

export const getDefaultState = () =>
  ({
    products,
    textures,
    vans,
  } as {
    products: Product[];
    textures: Texture[];
    vans: Van[];
  });

export const state = () => getDefaultState();

export type RootState = ReturnType<typeof state>;

export const getters: GetterTree<RootState, RootState> = {
  resetState(state) {
    Object.assign(state, getDefaultState());
  },

  vans: (state) => {
    return [...state.vans];
  },

  products: (state) => {
    return [...state.products];
  },

  textures: (state) => {
    return [...state.products];
  },

  woods: (state) => {
    return state.textures.filter(
      (texture) =>
        texture.subcategory === "Holz" || texture.subcategory === "Wood"
    );
  },

  vansBySupplier: (state) => (supplier: string) => {
    return state.vans.filter((van) => van.supplier === supplier);
  },

  vanSuppliers: (state) => {
    const vansBySupplier = groupBy(state.vans, "supplier");
    return Object.keys(vansBySupplier);
  },

  vanSupplierImage: (state) => (supplier: string) => {
    const vansOfSupplier = state.vans.filter(
      (van) => van.supplier === supplier
    );

    return vansOfSupplier.length > 0
      ? vansOfSupplier[0].image
      : defaultVanImage;
  },

  texturesByTopCategory: (state) => (topcategory: TopCategory) => {
    return state.textures.filter(
      (texture) => texture.topcategory === topcategory && !texture.deleted
    );
  },

  textureDetails: (state) => (id: number) => {
    const texture = state.textures.find((texture) => texture.id === id);
    return texture ? { ...texture } : null;
  },

  productsByIds: (state) => (ids: number[]) => {
    return state.products.filter((product) => ids.includes(product.id));
  },

  productsByTopcategory: (state) => (topcategory: TopCategory) => {
    return state.products
      .filter(
        (product) => product.topcategory === topcategory && !product.deleted
      )
      .sort((product) => (product.available ? -1 : 1));
  },

  productsBySubcategory: (state) => (subcategory: string) => {
    return state.products
      .filter(
        (product) => product.subcategory === subcategory && !product.deleted
      )
      .sort((product) => (product.available ? -1 : 1));
  },

  productDetails: (state) => (pid: number) => {
    const product = state.products.find((product) => product.id === pid);
    return product ? { ...product } : null;
  },

  selectedProductDetails:
    (state) => (selectedProducts: SelectedProduct[] | null) => {
      if (!selectedProducts) {
        return null;
      }

      const _ids: number[] = selectedProducts
        .map((product) => product.pid)
        .filter((id) => id !== null) as any;

      const ids = [...new Set(_ids)];

      return state.products.filter((product) => ids.includes(product.id));
    },
};

export const mutations: MutationTree<RootState> = {
  resetState(state) {
    Object.assign(state, getDefaultState());
  },
};

export const actions: ActionTree<RootState, RootState> = {};
