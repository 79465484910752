




















import Vue from "vue";
import { Component, Watch } from "nuxt-property-decorator";
import { User } from "../types/user";
import Footer from "~/components/layout/Footer.vue";
import Navbar from "~/components/layout/Navbar.vue";
import FeedbackButton from "~/components/layout/FeedbackButton.vue";
import CookieConsent from "~/components/layout/CookieConsent.vue";
import LoginModal from "~/components/modals/LoginModal.vue";
import ResetPasswordModal from "~/components/modals/ResetPasswordModal.vue";
import DeleteAccountModal from "~/components/modals/DeleteAccountModal.vue";
import ProductDetailsModal from "~/components/modals/ProductDetailsModal.vue";
import FeedbackModal from "~/components/modals/FeedbackModal.vue";
import { paymentModuleActive } from "~/config";

@Component({
  name: "DefaultLayout",
  components: {
    Navbar,
    Footer,
    CookieConsent,
    LoginModal,
    ResetPasswordModal,
    DeleteAccountModal,
    FeedbackButton,
    ProductDetailsModal,
    FeedbackModal,
    PaymentBlockerModal: () =>
      paymentModuleActive
        ? import("~/components/modals/PaymentBlockerModal.vue")
        : undefined,
    PaymentSuccessModal: () =>
      paymentModuleActive
        ? import("~/components/modals/PaymentSuccessModal.vue")
        : undefined,
    PaymentCancelModal: () =>
      paymentModuleActive
        ? import("~/components/modals/PaymentCancelModal.vue")
        : undefined,
    SelectPlanModal: () =>
      paymentModuleActive
        ? import("~/components/modals/SelectPlanModal.vue")
        : undefined,
  },
})
export default class DefaultLayout extends Vue {
  drawerToggle = null;
  feedbackHeading = "Feedback";

  get paymentModuleActive() {
    return paymentModuleActive;
  }

  get isAuth() {
    return this.$store.getters.isAuth as boolean;
  }

  get showFeedback(): boolean {
    return this.$store.getters.showFeedback;
  }

  get user(): User | null {
    return this.$store.getters.user;
  }

  @Watch("showFeedback")
  onChangeShowFeedback() {
    this.onShowFeedback();
  }

  mounted() {
    this.onShowFeedback();
  }

  onShowFeedback() {
    if (this.showFeedback) {
      setTimeout(() => {
        if (this.showFeedback && process.client && window && window.location) {
          this.feedbackHeading = this.$tc(
            "components.modals.feedback.do_you_like"
          );
          window.location.hash = "feedback";
        }
      }, 5000);
    }
  }

  async onCloseFeedbackModal() {
    this.feedbackHeading = "Feedback";
    if (this.showFeedback) {
      const errorMessage = await this.$store.dispatch("setShowedFeedback");

      if (errorMessage) {
        this.$notify({ type: "error", message: errorMessage });
      }
    }
  }
}
