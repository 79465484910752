import { Context } from "@nuxt/types";
import { decrypt } from "~/shared";

export default async function (context: Context) {
  const projectId = context.params.project;

  if (typeof projectId !== "string") {
    return;
  }

  const decryptedProjectId = decrypt(
    projectId,
    process.env.PROJECT_ID_SALT as string
  );

  await context.store.dispatch(
    "projects/getPublicProject",
    Number(decryptedProjectId)
  );
}
