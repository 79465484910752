import type { NuxtAxiosInstance } from "@nuxtjs/axios";
import { Lang } from "../types/locale";

export const signIn =
  ($axios: NuxtAxiosInstance) =>
  async (email: string, password: string, lang: Lang) => {
    const data = { email, password, lang };

    return await $axios.$post("/auth/login", data, {
      headers: {
        Accept: "application/json",
        Content: "application/json",
      },
    });
  };

export const signOut = ($axios: NuxtAxiosInstance) => async (lang: Lang) => {
  return await $axios.$post(
    "/auth/logout",
    { lang },
    {
      headers: {
        Accept: "application/json",
        Content: "application/json",
      },
    }
  );
};

export const signUp =
  ($axios: NuxtAxiosInstance) =>
  async (email: string, password: string, lang: Lang) => {
    const data = { email, password, lang };

    return await $axios.$post("/auth/register", data, {
      headers: {
        Accept: "application/json",
        Content: "application/json",
      },
    });
  };

export const confirmRegistration =
  ($axios: NuxtAxiosInstance) =>
  async (data: {
    email: string;
    code: string;
    lang: Lang;
    acceptCommercial: boolean;
  }) => {
    return await $axios.$post("/auth/confirmRegistration", data, {
      headers: {
        Accept: "application/json",
        Content: "application/json",
      },
    });
  };

export const resendConfirmCode =
  ($axios: NuxtAxiosInstance) => async (email: string, lang: Lang) => {
    const data = { email, lang };

    return await $axios.$post("/auth/resendConfirmCode", data, {
      headers: {
        Accept: "application/json",
        Content: "application/json",
      },
    });
  };

export const resetPassword =
  ($axios: NuxtAxiosInstance) => async (email: string, lang: Lang) => {
    const data = { email, lang };

    return await $axios.$post("/auth/resetPassword", data, {
      headers: {
        Accept: "application/json",
        Content: "application/json",
      },
    });
  };

export const confirmResetPassword =
  ($axios: NuxtAxiosInstance) =>
  async (email: string, code: string, newPassword: string, lang: Lang) => {
    const data = { email, code, newPassword, lang };

    return await $axios.$post("/auth/confirmResetPassword", data, {
      headers: {
        Accept: "application/json",
        Content: "application/json",
      },
    });
  };

export const deleteUser =
  ($axios: NuxtAxiosInstance) => async (email: string) => {
    return await $axios.$delete(`/auth/${email}`, {
      headers: {
        Accept: "application/json",
        Content: "application/json",
      },
    });
  };

export const refreshToken = ($axios: NuxtAxiosInstance) => async () => {
  return await $axios.$post(`/auth/refreshToken`, {
    headers: {
      Accept: "application/json",
      Content: "application/json",
    },
  });
};
