import { Object3D } from "three";
import { Store } from "vuex";
import { RootState } from "../store/three";
import { Project, SelectedProduct } from "./project";

export enum InteractionMode {
  TRANSLATE = "translate",
  ROTATE = "rotate",
  SCALE = "scale",
}

export enum HistoryActionType {
  DELETE = "DELETE",
  ADD = "ADD",
  MOVE = "MOVE",
  ROTATE = "ROTATE",
  SCALE = "SCALE",
  HIDE = "HIDE",
  SHOW = "SHOW",
  COLOR_CHANGE = "COLOR_CHANGE",
  PRODUCT_APPEARANCE_CHANGE = "PRODUCT_APPEARANCE_CHANGE",
}

export enum DragEventType {
  CREATE_PRODUCT = "CREATE_PRODUCT",
  CREATE_TEXTURE = "CREATE_TEXTURE",
  CREATE_FURNITURE = "CREATE_FURNITURE",
  CREATE_CUSTOM_SIZED_PRODUCT = "CREATE_CUSTOM_SIZED_PRODUCT",
}

export interface HistoryAction {
  type: HistoryActionType;
  pid: number | null;
  uuid: string;
  payload: any; // E.g. move parameters +10x, -5y, +7z
}

export interface ThreeWorld {
  init(data: {
    container: HTMLElement;
    project: Project;
    $store: Store<RootState>;
    isMobile: boolean;
    showVanHead: boolean;
    showGrid: boolean;
    showFront: boolean;
    showBack: boolean;
    showLeftWall: boolean;
    showRightWall: boolean;
    showGround: boolean;
    showRoof: boolean;
    mode: "translate" | "rotate" | "scale";
    gridSize: number;
    snippingSensitivity: number;
    moveClipping: number;
    rotationClipping: number;
    scaleClipping: number;
  }): Promise<void>;
  onResize(): void;
  setMode(mode: InteractionMode): void;
  setIsObjectSelected(isObjectSelected: boolean): void;
  setSelectedObject(uuid: string): void;
  changeSnippingSensitivity(newSensitivity: number): void;
  changeMoveClipping(newClipping: number): void;
  changeRotationClipping(newClipping: number): void;
  changeScaleClipping(newClipping: number): void;
  toggleVanHead(showVanHead: boolean, project: Project): void;
  toggleGrid(data: {
    showGrid: boolean;
    project: Project;
    gridSize: number;
    showFront: boolean;
    showBack: boolean;
    showLeftWall: boolean;
    showRightWall: boolean;
    showGround: boolean;
    showRoof: boolean;
  }): void;
  removeGrid(): void;
  createGrid(data: {
    showGrid: boolean;
    project: Project;
    gridSize: number;
    showFront: boolean;
    showBack: boolean;
    showLeftWall: boolean;
    showRightWall: boolean;
    showGround: boolean;
    showRoof: boolean;
  }): void;
  toggleFront(data: {
    showGrid: boolean;
    project: Project;
    gridSize: number;
    showFront: boolean;
    showBack: boolean;
    showLeftWall: boolean;
    showRightWall: boolean;
    showGround: boolean;
    showRoof: boolean;
  }): void;
  toggleBack(data: {
    showGrid: boolean;
    project: Project;
    gridSize: number;
    showFront: boolean;
    showBack: boolean;
    showLeftWall: boolean;
    showRightWall: boolean;
    showGround: boolean;
    showRoof: boolean;
  }): void;
  toggleLeft(data: {
    showGrid: boolean;
    project: Project;
    gridSize: number;
    showFront: boolean;
    showBack: boolean;
    showLeftWall: boolean;
    showRightWall: boolean;
    showGround: boolean;
    showRoof: boolean;
  }): void;
  toggleRight(data: {
    showGrid: boolean;
    project: Project;
    gridSize: number;
    showFront: boolean;
    showBack: boolean;
    showLeftWall: boolean;
    showRightWall: boolean;
    showGround: boolean;
    showRoof: boolean;
  }): void;
  toggleGround(data: {
    showGrid: boolean;
    project: Project;
    gridSize: number;
    showFront: boolean;
    showBack: boolean;
    showLeftWall: boolean;
    showRightWall: boolean;
    showGround: boolean;
    showRoof: boolean;
  }): void;
  toggleRoof(data: {
    showGrid: boolean;
    project: Project;
    gridSize: number;
    showFront: boolean;
    showBack: boolean;
    showLeftWall: boolean;
    showRightWall: boolean;
    showGround: boolean;
    showRoof: boolean;
  }): void;
  changeGridSize(data: {
    gridSize: number;
    project: Project;
    showFront: boolean;
    showBack: boolean;
    showLeftWall: boolean;
    showRightWall: boolean;
    showGround: boolean;
    showRoof: boolean;
  }): void;
  addProduct(product: SelectedProduct, select: boolean): Promise<void>;
  deleteProduct(uuid: string): void;
  changeProductAppearance(
    oldProductUuid: string,
    newProduct: SelectedProduct
  ): Promise<void>;
  setGroundTexture(pid: number | null, uuid: string | undefined | null): void;
  setGroundColor(newColor: number): void;
  removeGroundTexture(color: number): void;
  setWallsTexture(
    pid: number | null,
    project: Project,
    uuid: string | undefined | null
  ): void;
  setWallsColor(project: Project, newColor: number): void;
  removeWallsTexture(project: Project, color: number): void;
  getSize(object: Object3D): {
    height: number;
    width: number;
    length: number;
  };
  moveProduct(product: {
    uuid: string;
    deltaX: number;
    deltaY: number;
    deltaZ: number;
  }): void;
  rotateProduct(product: {
    uuid: string;
    deltaRx: number;
    deltaRy: number;
    deltaRz: number;
  }): void;
  scaleProduct(product: {
    uuid: string;
    deltaSx: number;
    deltaSy: number;
    deltaSz: number;
  }): void;
  render(): void;
  start(): void;
  stop(): void;
}
