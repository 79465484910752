//
//
//
//
//
//
//
//
//

import { parseEmoji } from "~/shared";
export default {
  name: "FeedbackButtonComponent",
  methods: {
    parseEmoji(emoji) {
      return parseEmoji(emoji, this);
    },
  },
};
