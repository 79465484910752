import { GetterTree, ActionTree, MutationTree } from "vuex";
import feed from "../config/feed.json";
import { Feed } from "~/types/feed";

export const getDefaultState = () =>
  ({
    feed,
  } as {
    feed: Feed[];
  });

export const state = () => getDefaultState();

export type RootState = ReturnType<typeof state>;

export const getters: GetterTree<RootState, RootState> = {
  resetState(state) {
    Object.assign(state, getDefaultState());
  },

  feed: (state) => {
    return [...state.feed];
  },

  getFeedById: (state) => (id: number) => {
    const feedEntry = state.feed.find((feedEntry) => feedEntry.id === id);
    return feedEntry ? { ...feedEntry } : null;
  },
};

export const mutations: MutationTree<RootState> = {
  resetState(state) {
    Object.assign(state, getDefaultState());
  },
};

export const actions: ActionTree<RootState, RootState> = {};
