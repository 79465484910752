export enum Shape {
  Wall = "Wall",
  Cube = "Cube",
  Sphere = "Sphere",
  Cylinder = "Cylinder",
  Bed = "Bed",
  Chair = "Chair",
  Kitchen = "Kitchen",
  Storage = "Storage",
  Storage1 = "Storage1",
  Storage2 = "Storage2",
  Storage3 = "Storage3",
  Storage4 = "Storage4",
  Storage5 = "Storage5",
  Table = "Table",
  Table4LegsRound = "Table4LegsRound",
  Table1LegRound = "Table1LegRound",
  Table4LegsSquare = "Table4LegsSquare",
  Table1LegSquare = "Table1LegSquare",
  TableRotatable = "TableRotatable",
  TableTopSquare = "TableTopSquare",
  TableTopRound = "TableTopRound",
  TableLeg = "TableLeg",
  TableLegRotatable = "TableLegRotatable",
  Board = "Board",
  Wheelcase = "Wheelcase",
  DoorSilverKnob = "DoorSilverKnob",
  DoorDarkKnob = "DoorDarkKnob",
  DoorSilverHandle = "DoorSilverHandle",
  DoorDarkHandle = "DoorDarkHandle",
  DoorSilverPull = "DoorSilverPull",
  DoorDarkPull = "DoorDarkPull",
  DriverSeat = "DriverSeat",
  DriverBench = "DriverBench",
}

export interface SelectedProduct {
  uuid: string; // Uniquely identiy object in scene
  x: number;
  y: number;
  z: number;
  rX: number;
  rY: number;
  rZ: number;
  sX: number;
  sY: number;
  sZ: number;
  width: number;
  height: number;
  length: number;
  color: number | null; // hex
  pid: number | null; // product id
  shape: Shape | null; // Furniture shapes
  name: string; // Name of the object for ctx menu
  visible: boolean; // Whether it should be rendered
}

export interface Project {
  id: number;
  created_at: Date;
  updated_at: Date;
  creator: number;
  total_cost: number;
  total_weight: number;
  products: SelectedProduct[] | null;
  width: number;
  height: number;
  length: number;
  weight: number;
  image: string;
  title: string;
  model: string | null;
  ground_color: number;
  walls_color: number;
}
