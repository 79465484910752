export enum TopCategory {
  VAN = "VAN",
  WALLS = "WALLS",
  GROUND = "GROUND",
  KITCHEN = "KITCHEN",
  SANITARY = "SANITARY",
  FURNITURE = "FURNITURE",
  WINDOWS = "WINDOWS",
  DECORATION = "DECORATION",
  LAYOUT = "LAYOUT",
}

export const categoryIcon = (category: TopCategory) => {
  switch (category) {
    case TopCategory.VAN:
      return require("~/assets/icons/van.png");
    case TopCategory.WALLS:
      return require("~/assets/icons/wall.png");
    case TopCategory.GROUND:
      return require("~/assets/icons/floor.png");
    case TopCategory.KITCHEN:
      return require("~/assets/icons/kitchen.png");
    case TopCategory.SANITARY:
      return require("~/assets/icons/sanitary.png");
    case TopCategory.FURNITURE:
      return require("~/assets/icons/furniture.png");
    case TopCategory.WINDOWS:
      return require("~/assets/icons/windows.png");
    case TopCategory.DECORATION:
      return require("~/assets/icons/decoration.png");
  }
};

export const categoryOrder: { [category: string]: number } = {
  [TopCategory.VAN]: 0,
  [TopCategory.WALLS]: 1,
  [TopCategory.GROUND]: 2,
  [TopCategory.KITCHEN]: 3,
  [TopCategory.SANITARY]: 4,
  [TopCategory.FURNITURE]: 5,
  [TopCategory.WINDOWS]: 6,
  [TopCategory.DECORATION]: 7,
};
