import {
  CancellablePromiseLike,
  SequentialTaskQueue,
} from "sequential-task-queue";
import { v4 } from "uuid";

export class TaskQueue {
  queue: SequentialTaskQueue | null = null;
  timeout: number = 5000;
  isLoading: boolean = false;
  isLoadingCbs: Array<{ id: string; cb: (isLoading: boolean) => void }> = [];

  constructor(timeout = 5000) {
    this.init(timeout);
  }

  private init(timeout = 5000) {
    this.timeout = timeout;
    this.queue = new SequentialTaskQueue();
    this.queue.on("drained", () => {
      this.isLoading = false;
      this.notifyCbs();
    });
  }

  push(
    task: Function,
    args: any[],
    timeout?: number
  ): CancellablePromiseLike<any> | null {
    if (!this.queue) {
      this.init();
    }

    if (!this.queue) return null;

    this.isLoading = true;
    this.notifyCbs();

    const _timeout = typeof timeout === "number" ? timeout : this.timeout;

    return this.queue.push(task, {
      timeout: _timeout,
      args,
    });
  }

  notifyCbs() {
    for (const cb of this.isLoadingCbs) {
      cb.cb(this.isLoading);
    }
  }

  registerIsLoadingCb(cb: (isLoading: boolean) => void) {
    const id = v4();
    this.isLoadingCbs.push({ id, cb });
    return id;
  }

  deregisterIsLoadingCb(id: string | null) {
    const idx = this.isLoadingCbs.findIndex((val) => val.id === id);

    if (idx > 0) {
      this.isLoadingCbs.splice(idx, 1);
    }
  }

  async kill() {
    if (!this.queue) return;

    await this.queue.close(true);
  }
}
