






































































































































































































import Vue from "vue";
import { Component } from "nuxt-property-decorator";
import {
  faAngleRight,
  faTimes,
  faInfoCircle,
} from "@fortawesome/free-solid-svg-icons";
import amplitude from "amplitude-js";
import { categoryDe } from "~/locales/translations";
import { Project } from "~/types/project";
import {
  getPrice,
  getWeight,
  getProductsWithAmountAndPrice,
  parseEmoji,
} from "~/shared";
import { Product } from "~/types/product";

@Component({
  name: "ProductDetailsModalComponent",
  components: {},
})
export default class ProductDetailsModal extends Vue {
  get project(): Project | null {
    return this.$store.getters["projects/activeProject"];
  }

  get faTimes() {
    return faTimes;
  }

  get faInfoCircle() {
    return faInfoCircle;
  }

  get faAngleRight() {
    return faAngleRight;
  }

  get showPopup() {
    return this.$store.getters.showProductDetailsModal as boolean;
  }

  get hasValidProductId() {
    const productId = this.$route.query.productId;
    return (
      typeof productId === "string" &&
      Number.isInteger(Number(productId)) &&
      Number(productId) > 0
    );
  }

  get productId() {
    return this.hasValidProductId ? Number(this.$route.query.productId) : null;
  }

  get productDetails(): Product | null {
    return this.productId != null
      ? this.$store.getters["products/productDetails"](this.productId)
      : null;
  }

  get productsWithAmountAndPrice() {
    if (!this.productDetails) return null;
    return getProductsWithAmountAndPrice([this.productDetails], this.project);
  }

  get selectedLength() {
    return this.productsWithAmountAndPrice &&
      typeof this.productsWithAmountAndPrice[0]?.priceAmount === "number"
      ? Math.ceil(this.productsWithAmountAndPrice[0]?.priceAmount)
      : null;
  }

  get productEstimatedWeight() {
    return this.productsWithAmountAndPrice &&
      this.productsWithAmountAndPrice[0]?.weight &&
      typeof this.productsWithAmountAndPrice[0]?.weightAmount === "number"
      ? getWeight(
          this.productsWithAmountAndPrice[0]?.weightAmount *
            this.productsWithAmountAndPrice[0]?.weight
        )
      : null;
  }

  get productEstimatedPrice() {
    return this.productsWithAmountAndPrice &&
      typeof this.productsWithAmountAndPrice[0]?.totalPrice === "number"
      ? getPrice(this.productsWithAmountAndPrice[0]?.totalPrice)
      : null;
  }

  get isGroundOrWalls() {
    return (
      this.productDetails &&
      (this.productDetails.topcategory === "GROUND" ||
        this.productDetails.topcategory === "WALLS")
    );
  }

  get image() {
    return this.productDetails ? this.productDetails.image : "#";
  }

  get name() {
    return this.productDetails ? this.productDetails.title : "";
  }

  get description() {
    return this.productDetails ? this.productDetails.description : null;
  }

  get cheap() {
    return this.productDetails ? this.productDetails.cheap : false;
  }

  get available() {
    return this.productDetails ? this.productDetails.available === false : true;
  }

  get showLink() {
    return this.productDetails != null;
  }

  get linkHref() {
    if (!this.productDetails) return "#";
    return this.productDetails.link;
  }

  get shopImage() {
    return this.productDetails ? this.productDetails.shop_image : null;
  }

  get shopName() {
    return this.productDetails ? this.productDetails.shop_name : null;
  }

  get price() {
    return this.productDetails
      ? getPrice(this.productDetails.price)
      : getPrice(0);
  }

  get weight() {
    return this.productDetails
      ? getWeight(this.productDetails.weight)
      : getWeight(0);
  }

  get category() {
    return this.productDetails
      ? categoryDe[this.productDetails.topcategory]
      : null;
  }

  get type() {
    return this.productDetails ? this.productDetails.type : null;
  }

  get size() {
    return this.productDetails &&
      this.productDetails.length &&
      this.productDetails.height &&
      this.productDetails.width
      ? `${this.productDetails.length}cm x ${this.productDetails.width}cm x ${this.productDetails.height}cm`
      : null;
  }

  parseEmoji(text: string) {
    return parseEmoji(text, this);
  }

  onClick(link: string, pid: number) {
    window.open(link, "_blank");
    amplitude.getInstance().logEvent("shop_link_clicked", { pid, link });
  }

  onClose() {
    this.$store.commit("setShowProductDetailsModal", false);
  }

  mounted() {}
}
