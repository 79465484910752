import { Context, Plugin } from "@nuxt/types";
import { IAuthResponse } from "~/types/response";

const tokenInterceptor: Plugin = (context: Context) => {
  context.$axios.onRequest((config) => {
    const auth: IAuthResponse | null = context.store.getters.auth;

    if (
      auth &&
      auth.jwt &&
      config.headers != null &&
      config.headers["X-Requested-With"] == null
    ) {
      config.headers = {
        Authorization: "Bearer " + auth.jwt,
        ...config.headers,
      };
    }
    return config;
  });
};

export default tokenInterceptor;
