import { Context } from "@nuxt/types";
import { User } from "../types/user";
import { userLangToI18nLang } from "~/shared";

export default async function (context: Context) {
  const user: User | null = context.store.getters.user;

  if (user && user.lang) {
    const lang = userLangToI18nLang(user.lang);
    await context.app.i18n.setLocale(lang);
  }
}
