import * as SentryTypes from "@sentry/minimal";

/* eslint-disable import/no-mutable-exports */
let $sentry: typeof SentryTypes;
let $sentryReady: () => Promise<typeof SentryTypes>;

export function initializeSentry(
  sentryInstance: typeof SentryTypes,
  sentryReady: () => Promise<typeof SentryTypes>
) {
  $sentry = sentryInstance;
  $sentryReady = sentryReady;
}

export { $sentry, $sentryReady };
