var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('input',{staticClass:"modal-toggle",attrs:{"type":"checkbox"},domProps:{"checked":_vm.showPopup}}),_vm._v(" "),_c('div',{staticClass:"modal"},[_c('div',{staticClass:"modal-box lg:max-w-full lg:w-8/12 relative"},[_c('button',{staticClass:"close-icon",on:{"click":_vm.onClose}},[_c('font-awesome-icon',{attrs:{"icon":_vm.faTimes}})],1),_vm._v(" "),(_vm.hasValidProductId)?[_c('figure',[_c('img',{directives:[{name:"lazy-load",rawName:"v-lazy-load"}],staticClass:"preview-img",attrs:{"data-src":_vm.image,"alt":_vm.name}})]),_vm._v(" "),_c('div',{staticClass:"flex flex-wrap"},[_c('div',{staticClass:"w-full lg:w-2/3 pr-2"},[_c('h1',{staticClass:"text-2xl mt-4"},[_vm._v("\n              "+_vm._s(_vm.name)+"\n              "),(_vm.cheap)?_c('div',{staticClass:"tooltip tooltip-right",attrs:{"data-tip":_vm.$t('components.tables.selected_products.tooltips.cheap')}},[_c('div',{staticClass:"emoji-inline-medium ml-1",domProps:{"innerHTML":_vm._s(_vm.parseEmoji('🔥'))}})]):_vm._e(),_vm._v(" "),(_vm.available)?_c('div',{staticClass:"tooltip tooltip-right",attrs:{"data-tip":_vm.$t('components.tables.selected_products.tooltips.available')}},[_c('div',{staticClass:"emoji-inline-medium ml-1",domProps:{"innerHTML":_vm._s(_vm.parseEmoji('⛔'))}})]):_vm._e()]),_vm._v(" "),_c('h2',{staticClass:"text-lg mb-1"},[_vm._v("\n              "+_vm._s(_vm.$t("components.modals.product_details.headings.description"))+"\n            ")]),_vm._v(" "),(_vm.description)?_c('p',[_vm._v("\n              "+_vm._s(_vm.description)+"\n            ")]):_c('p',{staticClass:"mb-5"},[_vm._v("\n              "+_vm._s(_vm.$t(
                  "components.modals.product_details.paragraphs.no_description"
                ))+"\n            ")])]),_vm._v(" "),_c('div',{staticClass:"w-full lg:w-1/3 pl-2"},[_c('div',{staticClass:"card shadow-xl overflow-x-auto"},[_c('div',{staticClass:"card-body"},[_c('h2',{staticClass:"text-lg mb-1"},[_vm._v("\n                  "+_vm._s(_vm.$t("components.modals.product_details.headings.details"))+"\n                ")]),_vm._v(" "),(_vm.category)?_c('div',{staticClass:"flex flex-col w-full mt-3"},[_c('p',{staticClass:"mb-0"},[_vm._v("\n                    "+_vm._s(_vm.$t(
                        "components.modals.product_details.headings.category"
                      ))+"\n                  ")]),_vm._v(" "),_c('p',{staticClass:"mb-0 font-semibold"},[_vm._v(_vm._s(_vm.category))])]):_vm._e(),_vm._v(" "),(_vm.type)?_c('div',{staticClass:"flex flex-col w-full mt-3"},[_c('p',{staticClass:"mb-0"},[_vm._v("\n                    "+_vm._s(_vm.$t("components.modals.product_details.headings.typ"))+"\n                  ")]),_vm._v(" "),_c('p',{staticClass:"mb-0 font-semibold"},[_vm._v(_vm._s(_vm.type))])]):_vm._e(),_vm._v(" "),(_vm.size)?_c('div',{staticClass:"flex flex-col w-full mt-3"},[_c('p',{staticClass:"mb-0"},[_vm._v("\n                    "+_vm._s(_vm.$t("components.modals.product_details.headings.size"))+"\n                  ")]),_vm._v(" "),_c('p',{staticClass:"mb-0 font-semibold"},[_vm._v(_vm._s(_vm.size))])]):_vm._e(),_vm._v(" "),(_vm.price)?_c('div',{staticClass:"flex flex-col w-full mt-3"},[_c('p',{staticClass:"mb-0"},[_vm._v("\n                    "+_vm._s(_vm.$t(
                        "components.modals.product_details.headings.unit_price"
                      ))+"\n                  ")]),_vm._v(" "),_c('p',{staticClass:"mb-0 font-semibold"},[_vm._v(_vm._s(_vm.price))])]):_vm._e(),_vm._v(" "),(_vm.weight)?_c('div',{staticClass:"flex flex-col w-full mt-3"},[_c('p',{staticClass:"mb-0"},[_vm._v("\n                    "+_vm._s(_vm.$t(
                        "components.modals.product_details.headings.unit_weight"
                      ))+"\n                  ")]),_vm._v(" "),_c('p',{staticClass:"mb-0 font-semibold"},[_vm._v(_vm._s(_vm.weight))])]):_vm._e(),_vm._v(" "),(_vm.selectedLength)?_c('div',{staticClass:"flex flex-col w-full mt-3"},[_c('div',{staticClass:"mb-0"},[_vm._v("\n                    "+_vm._s(_vm.$t("components.modals.product_details.headings.amount"))+"\n                    "),(_vm.isGroundOrWalls)?_c('div',{staticClass:"tooltip tooltip-right",attrs:{"data-tip":_vm.$t(
                          'components.tables.selected_products.tooltips.amount'
                        )}},[_c('font-awesome-icon',{staticClass:"ml-1",attrs:{"icon":_vm.faInfoCircle}})],1):_c('div',{staticClass:"tooltip tooltip-right",attrs:{"data-tip":"Verplant von diesem Produkt"}},[_c('font-awesome-icon',{staticClass:"ml-1",attrs:{"icon":_vm.faInfoCircle}})],1)]),_vm._v(" "),_c('p',{staticClass:"mb-0 font-semibold"},[_vm._v(_vm._s(_vm.selectedLength))])]):_vm._e(),_vm._v(" "),(_vm.productEstimatedPrice)?_c('div',{staticClass:"flex flex-col w-full mt-3"},[_c('p',{staticClass:"mb-0"},[_vm._v("\n                    "+_vm._s(_vm.$t(
                        "components.modals.product_details.headings.price_for_amount"
                      ))+"\n                  ")]),_vm._v(" "),_c('p',{staticClass:"mb-0 font-semibold"},[_vm._v("\n                    "+_vm._s(_vm.productEstimatedPrice)+"\n                  ")])]):_vm._e(),_vm._v(" "),(_vm.productEstimatedWeight)?_c('div',{staticClass:"flex flex-col w-full mt-3"},[_c('p',{staticClass:"mb-0"},[_vm._v("\n                    "+_vm._s(_vm.$t(
                        "components.modals.product_details.headings.weight_for_amount"
                      ))+"\n                  ")]),_vm._v(" "),_c('p',{staticClass:"mb-0 font-semibold"},[_vm._v("\n                    "+_vm._s(_vm.productEstimatedWeight)+"\n                  ")])]):_vm._e(),_vm._v(" "),(_vm.shopImage && _vm.shopName)?_c('div',{staticClass:"flex flex-col mt-3"},[_c('p',{staticClass:"mb-0"},[_vm._v("Shop")]),_vm._v(" "),_c('img',{directives:[{name:"lazy-load",rawName:"v-lazy-load"}],attrs:{"data-src":_vm.shopImage,"alt":_vm.shopName,"width":"76","height":"32"}})]):_vm._e(),_vm._v(" "),(_vm.showLink)?_c('a',{staticClass:"to-shop-btn",attrs:{"href":_vm.linkHref},on:{"click":function($event){$event.preventDefault();return _vm.onClick(_vm.linkHref, _vm.productId)}}},[_vm._v(_vm._s(_vm.$t("components.modals.product_details.buttons.to_shop"))),_c('font-awesome-icon',{staticClass:"ml-1",attrs:{"icon":_vm.faAngleRight}})],1):_vm._e()])])])])]:_c('p',{staticClass:"font-bold mb-0"},[_vm._v("\n        "+_vm._s(_vm.$t("components.modals.product_details.paragraphs.unknown_product"))+"\n      ")])],2)])])}
var staticRenderFns = []

export { render, staticRenderFns }