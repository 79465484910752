//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { parseEmoji } from "~/shared";

export default {
  data() {
    return {
      isOpen: false,
      faTimes,
    };
  },
  mounted() {
    if (!this.getGDPR()) {
      this.isOpen = true;
    }
  },
  methods: {
    getGDPR() {
      if (process.browser) {
        return this.$store.getters["cookies/cookiesAccepted"];
      }
    },
    accept() {
      if (process.browser) {
        this.$store.dispatch("cookies/onAcceptCookies", true);
      }
    },
    parseEmoji(emoji) {
      return parseEmoji(emoji, this);
    },
  },
};
